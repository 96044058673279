import commonService from './common';

const server = commonService.getAxiosInstance();

const get_profile = async () => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.get(`/profile`, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const update_password = async (payload) => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.post(`/profile/change_password`, payload, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const update_profile = async (payload) => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.patch(`/profile`, payload, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export default {
    get_profile,
    update_password,
    update_profile
}