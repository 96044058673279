import AsyncStorage from '@react-native-async-storage/async-storage';
import React from 'react';
import {StyleSheet, View, Text, SafeAreaView, ScrollView, TouchableOpacity, RefreshControl, TextInput, Switch, Image, Platform} from 'react-native';
import AlertPopUpModal from '../components/AlertPopUpModal';
import BottomNavigationBar from '../components/BottomNavigationBar';
import config from '../config';
import I18n from '../utils/i18n';
import RightChevronIcon from './../assets/icons/right-chevron.svg';
import MinusIcon from './../assets/icons/minus.svg';
import CheckIcon from './../assets/icons/check.svg';
import authService from '../services/auth';
import userService from './../services/user';
import barsService from './../services/bars';
import statisticsService from './../services/statistics';
import reportService from '../services/reports';
import KeepAwake from 'react-native-keep-awake';
import RefreshButton from '../components/RefreshButton';
import commonStyles from './../styles/common';
let printer = require('./../utils/node-thermal-printer/printer');

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.default_modal_props = {
      icon: '',
      title: '',
      text: '',
      okCallback: null
    }

    this.loading_modal_props = {
      ...this.default_modal_props,
      icon: 'loading',
      title: I18n.t('loading')
    }

    this.state = {
      profile: {},
      isDailyReportSettingsVisible: false,
      isChangePasswordVisible: false,
      isChangeLanguageVisible: false,
      old_password: '',
      new_password: '',
      confirm_password: '',
      loading: false,
      isModalVisible: false,
      isAlwaysOnDisplay: false,
      printerIp: '',
      role: null,
      modal: {
        icon: '',
        title: '',
        text: '',
      },
      currentLanguage: I18n.locale,
      // report settings
      dailyReport_cashTurnover: '',
      dailyReport_cardTurnover: '',
    }
  }

  async componentDidMount(show_loading = true) {

    await this.setState({
      loading: true,
      isModalVisible: show_loading && true,
      modal: this.loading_modal_props,
      role: await AsyncStorage.getItem('role'),
      printerIp: await AsyncStorage.getItem('printerIp')
    });

    let isAlwaysOnDisplay = await AsyncStorage.getItem('isAlwaysOnDisplay');
    if (isAlwaysOnDisplay) {
      isAlwaysOnDisplay = JSON.parse(isAlwaysOnDisplay);
      if (isAlwaysOnDisplay) {
        if (Platform.OS != 'web') {
          KeepAwake.activate();
        }
        this.setState({ isAlwaysOnDisplay: true });
      } else {
        if (Platform.OS != 'web') {
          KeepAwake.deactivate();
        }
        this.setState({ isAlwaysOnDisplay: false });
      }
    } else {
      isAlwaysOnDisplay = false;
    }

    let profileRes = await userService.get_profile();
    if (profileRes.error) {
      try {
        // await AsyncStorage.clear()
      } catch (e) {
        // clear error
      }
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: 'Login', params: { redirectTo: 'Profile' } }],
      });
      return;
    }
    this.setState({
      profile: profileRes.user,
      loading: show_loading && false,
      isModalVisible: false
    });
  }

  validatePasswords() {
    return this.state.new_password === this.state.confirm_password;
  }

  validateOldNewPassword() {
    return this.state.old_password !== this.state.new_password;
  }

  isChangePasswordValid() {
    let valid = false;
    if (
      this.state.old_password.length > 0 &&
      this.state.new_password.length >= 6 &&
      this.state.confirm_password.length >= 6
    ) {
      valid = true;
    }

    valid = valid && this.validateOldNewPassword() && this.validatePasswords();

    return valid;
  }

  isDailyReportSettingsValid() {
    let valid = false;
    if (
      this.state.dailyReport_cashTurnover.length > 0 &&
      this.state.dailyReport_cardTurnover.length > 0
    ) {
      valid = true;
    }

    if (valid && !isNaN(this.state.dailyReport_cashTurnover) && !isNaN(this.state.dailyReport_cardTurnover)) {
      valid = true;
    } else {
      valid = false;
    }

    return valid;
  }

  async generateDailyReport() {
    try {

      if (!this.isDailyReportSettingsValid()) {
        return
      }

      try {
        // save report data
        let payload = {
          cashTurnover: this.state.dailyReport_cashTurnover,
          cardTurnover: this.state.dailyReport_cardTurnover
        }
        let reportRes = await reportService.save_report_data(payload);
        if (reportRes.error) {
          this.setState({
            loading: false,
            isModalVisible: true,
            modal: {
              ...this.default_modal_props,
              icon: 'error',
              title: I18n.t('error'),
              text: reportRes.error_code ? I18n.t(reportRes.error_code) : reportRes.error,
              okCallback: () => {
                this.setState({ isModalVisible: false });
              }
            }
          });
          return
        }


        // print report
        let printRes = await statisticsService.printDailyStatistics();
        if (printRes.error) {
          this.setState({
            loading: false,
            isModalVisible: true,
            modal: {
              ...this.default_modal_props,
              icon: 'error',
              title: I18n.t('error'),
              text: I18n.t(printRes.error_code),
              okCallback: () => {
                this.setState({ isModalVisible: false });
              }
            }
          });
          return
        }

        this.setState({
          loading: false,
          isModalVisible: true,
          modal: {
            ...this.default_modal_props,
            icon: 'success',
            title: I18n.t('report_generated'),
            okCallback: () => {
              this.setState({
                isModalVisible: false
              });
            }
          }
        });

      } catch (error) {
        console.log("Error generating report", error.message);
        this.setState({
          loading: false,
          isModalVisible: true,
          modal: {
            ...this.default_modal_props,
            icon: 'error',
            title: I18n.t('error'),
            text: I18n.t('unknown_error'),
            okCallback: () => {
              this.setState({ isModalVisible: false });
            }
          }
        });
      }    
    } catch (error) {
      console.log(error);
    }
  }

  async updatePassword() {
    if (!this.isChangePasswordValid()) {
      return
    }

    this.setState({ loading: true, isModalVisible: true, modal: this.loading_modal_props });

    let payload = {
      oldPassword: this.state.old_password,
      newPassword: this.state.new_password
    }


    let updatePasswordRes = await userService.update_password(payload);
    if (updatePasswordRes.error) {
      this.setState({
        loading: false,
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t(updatePasswordRes.error_code),
          okCallback: () => {
            this.setState({ isModalVisible: false });
          }
        }
      });
      return
    } else {
      this.setState({
        loading: false,
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'success',
          title: I18n.t('password_updated'),
          okCallback: () => {
            this.setState({
              old_password: '',
              new_password: '',
              confirm_password: '',
              isChangePasswordVisible: false,
              isModalVisible: false
            });
          }
        }
      });
    }
  }

  toggleIsAlwaysOnDisplay() {
    this.setState({ isAlwaysOnDisplay: !this.state.isAlwaysOnDisplay }, async () => {
      await AsyncStorage.setItem('isAlwaysOnDisplay', JSON.stringify(this.state.isAlwaysOnDisplay));
      if (this.state.isAlwaysOnDisplay) {
        if (Platform.OS != 'web') {
          KeepAwake.activate();
        }
      } else {
        if (Platform.OS != 'web') {
          KeepAwake.deactivate();
        }
      }
    });
  }
  
  async conf(msg) {
    return new Promise((resolve, reject) => {
      this.setState({
        isModalVisible: true,
        modal: {
          icon: 'info',
          title: msg,
          okCallback: () => {
            resolve(true);
            this.setState({
              isModalVisible: false,
            });
          },
          okText: I18n.t('yes'),
          cancelCallback: () => {
            resolve(false);
            this.setState({
              isModalVisible: false
            })
          },
          cancelText: I18n.t('no')
        }
      });
    });
  }

  async toggleBarStatus(bar) {
    let ok = await this.conf(I18n.t('are_you_sure_you_want_to_change_bar_status'));
    if (!ok) return;

    this.setState({ loading: true, isModalVisible: true, modal: this.loading_modal_props });

    let payload = {}
    if (bar.status === 'active') {
      payload.status = 'inactive';
    } else {
      payload.status = 'active';
    }

    let updateBarRes = await barsService.update_bar(bar._id, payload);
    if (updateBarRes.error) {
      this.setState({
        loading: false,
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t(updateBarRes.error_code),
          okCallback: () => {
            this.setState({
              loading: false,
              isModalVisible: false
            });
            this.componentDidMount(false);
          }
        }
      });
      return
    }

    this.setState({
      loading: false,
      isModalVisible: false
    });
    this.componentDidMount(false);
  }

  render() {
    return (
      <SafeAreaView style={styles.container}>
        <View style={commonStyles.centeredView}>
        <ScrollView style={styles.scrollView}
          refreshControl={
            <RefreshControl
              refreshing={this.state.loading}
              onRefresh={() => {
                this.componentDidMount(false);
              }}
            />
          }
        >

          {(Platform.OS == 'web') ? (
            <RefreshButton
              onPress={() => {
                this.componentDidMount(false);
              }}
              refreshing={this.state.loading}
            />
          ) : null}

          
          <View style={{backgroundColor: 'white', marginTop: 10, marginBottom: 10, padding: 10}}>
            <View style={{flex: 1, justifyContent: 'space-between', marginBottom: 15, flexDirection: 'column'}}>
              <Text style={{fontSize: 15, marginBottom: 5, color: 'black'}}>{I18n.t('full_name')}</Text>
              <Text style={{fontSize: 19, fontWeight: '700', color: 'black'}}>{this.state.profile.fullname}</Text>
            </View>
            {(!this.state.profile.isGuest) ? (
              <>
                <View style={{flex: 1, justifyContent: 'space-between', marginBottom: 15, flexDirection: 'column'}}>
                  <Text style={{fontSize: 15, marginBottom: 5, color: 'black'}}>{I18n.t('email')}</Text>
                  <Text style={{fontSize: 19, fontWeight: '700', color: 'black'}}>{this.state.profile.email}</Text>
                </View>
                <View style={{flex: 1, justifyContent: 'space-between', flexDirection: 'column', paddingBottom: 10}}>
                  <Text style={{fontSize: 15, marginBottom: 5, color: 'black'}}>{I18n.t('username')}</Text>
                  <Text style={{fontSize: 19, fontWeight: '700', color: 'black'}}>{this.state.profile.username}</Text>
                </View>
              </>
            ) : null}
          </View>
          

          {(this.state.role === config.roles.CASHIER.key && this.state?.profile?.barId) ? (

            <View style={{backgroundColor: 'white', marginTop: 10, marginBottom: 10, padding: 10}}>

              {this.state.profile.barId.map((bar) => {
                return (
                  <View
                    key={bar._id}
                    style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 5, marginBottom: 5}}
                  >
                    <Text style={{fontSize: 17, color: 'black'}}>{bar.name}</Text>
                    <Switch
                      trackColor={{ false: "#767577", true: "darkgray"}}
                      thumbColor={(bar.status == "active") ? config.primaryColor : "#f4f3f4"}
                      ios_backgroundColor="#3e3e3e"
                      onValueChange={async () => await this.toggleBarStatus(bar)}
                      value={bar.status == "active" ? true : false}
                    />
                  </View>
                )
              })}

            </View>
          ) : null}


          {(this.state.role === config.roles.CASHIER.key || this.state.role === config.roles.SERVER.key) ? (
            <View style={{backgroundColor: 'white', marginTop: 10, marginBottom: 10, padding: 10}}>
              <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 5}}>
                <Text style={{fontSize: 17, color: 'black'}}>{I18n.t('always_on_display')}</Text>
                <Switch
                  trackColor={{ false: "#767577", true: "darkgray"}}
                  thumbColor={this.state.isAlwaysOnDisplay ? config.primaryColor : "#f4f3f4"}
                  ios_backgroundColor="#3e3e3e"
                  onValueChange={() => this.toggleIsAlwaysOnDisplay()}
                  value={this.state.isAlwaysOnDisplay}
                />
              </View>
              {(this.state.role === config.roles.CASHIER.key) ? (
                <>
                  <TouchableOpacity style={{marginTop: 10}}
                    onPress={() => {
                      if (this.state.isDailyReportSettingsVisible) {
                        this.setState({
                          dailyReport_cashTurnover: '',
                          dailyReport_cardTurnover: ''
                        });
                      }
                      this.setState({
                        isDailyReportSettingsVisible: !this.state.isDailyReportSettingsVisible
                      })
                    }}
                    >
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                      <Text style={{fontSize: 17, color: 'black'}}>{I18n.t('daily_report')}</Text>
                      {this.state.isDailyReportSettingsVisible ? (
                        <>
                          {(Platform.OS == "web") ? (
                            <Image source={require('./../assets/icons/minus.svg')} style={{width: 15, height: 15}} />
                          ) : (
                            <MinusIcon />
                          )}
                        </>
                      ) : (
                        <>
                          {(Platform.OS == "web") ? (
                            <Image source={require('./../assets/icons/right-chevron.svg')} style={{width: 15, height: 15}} />
                          ) : (
                            <RightChevronIcon />
                          )}
                        </>
                      )}
                    </View>
                  </TouchableOpacity>
                  {this.state.isDailyReportSettingsVisible ? (
                    <View style={{marginTop: 10, marginBottom: 5}}>
                      
                      <Text style={{fontSize: 15, marginBottom: 5, color: 'black'}}>{I18n.t('cash_turnover')}</Text>
                      <TextInput
                        style={{...styles.textInput, textAlign: 'center'}}
                        onChangeText={text => this.setState({
                          dailyReport_cashTurnover : text
                        })}
                        value={this.state.dailyReport_cashTurnover}
                        placeholder={I18n.t('cash_turnover')}
                        placeholderTextColor="gray"
                        keyboardType="numeric"
                      />
                      
                      <Text style={{fontSize: 15, marginBottom: 5, color: 'black'}}>{I18n.t('card_turnover')}</Text>
                      <TextInput
                        style={{...styles.textInput, textAlign: 'center'}}
                        onChangeText={text => this.setState({
                          dailyReport_cardTurnover : text
                        })}
                        value={this.state.dailyReport_cardTurnover}
                        placeholder={I18n.t('card_turnover')}
                        placeholderTextColor="gray"
                        keyboardType="numeric"
                      />


                      <TouchableOpacity
                        style={
                          this.isDailyReportSettingsValid() ? 
                            {backgroundColor: config.primaryColor, padding: 10, borderRadius: 5} :
                            {backgroundColor: 'gray', padding: 10, borderRadius: 5}
                        }
                        disabled={!this.isDailyReportSettingsValid()}
                        onPress={async () => {
                          await this.generateDailyReport();
                        }}
                      >
                        <Text style={{color: 'white', textAlign: 'center', fontSize: 17, fontWeight: '700'}}>{I18n.t('generate_report')}</Text>
                      </TouchableOpacity>
                    </View>
                  ) : <View style={{marginBottom: 10}}></View>}

                </>
              ) : null}
            </View>
          ) : null}

          {(this.state.role === config.roles.USER.key || this.state.role === config.roles.CASHIER.key || this.state.role === config.roles.SERVER.key) ? (
            <TouchableOpacity style={{backgroundColor: 'white', marginTop: 10, padding: 10}}
              onPress={() => {
                this.props.navigation.navigate('OrderGroupHistory', { history: true });
              }}
            >
              <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Text style={{fontSize: 17, color: 'black'}}>{I18n.t('order_history')}</Text>
                {(Platform.OS == "web") ? (
                  <Image source={require('./../assets/icons/right-chevron.svg')} style={{width: 15, height: 15}} />
                ) : (
                  <RightChevronIcon />
                )}
              </View>
            </TouchableOpacity>
          ) : ( null )}


          <TouchableOpacity style={{backgroundColor: 'white', marginTop: 10, padding: 10}}
            onPress={() => {
              this.setState({
                isChangeLanguageVisible: !this.state.isChangeLanguageVisible
              })
            }}
          >
            <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              <Text style={{fontSize: 17, color: 'black'}}>{I18n.t('change_language')}</Text>
              {this.state.isChangeLanguageVisible ? (
                <>
                  {(Platform.OS == "web") ? (
                    <Image source={require('./../assets/icons/minus.svg')} style={{width: 15, height: 15}} />
                  ) : (
                    <MinusIcon />
                  )}
                </>
              ) : (
                <>
                  {(Platform.OS == "web") ? (
                    <Image source={require('./../assets/icons/right-chevron.svg')} style={{width: 15, height: 15}} />
                  ) : (
                    <RightChevronIcon />
                  )}
                </>
              )}
            </View>
          </TouchableOpacity>
          {this.state.isChangeLanguageVisible ? (
            <View style={{backgroundColor: 'white', marginBottom: 10, padding: 10}}>
              <TouchableOpacity
                style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 5, marginBottom: 5}}
                onPress={async () => {
                  AsyncStorage.setItem('language', 'en');
                  I18n.locale = 'en';
                  this.setState({
                    currentLanguage: 'en',
                  })
                  await userService.update_profile({
                    language: 'en'
                  });
                }}
              >
                <Text style={{fontSize: 17, color: 'black'}}>English</Text>
                {this.state.currentLanguage == 'en' ? (
                  <>
                    {(Platform.OS == "web") ? (
                      <Image source={require('./../assets/icons/check.svg')} style={{width: 20, height: 20}} />
                    ) : (
                      <CheckIcon />
                    )}
                  </>
                ) : null}
              </TouchableOpacity>

              <TouchableOpacity
                style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 5, marginBottom: 5}}
                onPress={async () => {
                  AsyncStorage.setItem('language', 'fr');
                  I18n.locale = 'fr';
                  this.setState({
                    currentLanguage: 'fr',
                  })
                  await userService.update_profile({
                    language: 'fr'
                  });
                }}
              >
                <Text style={{fontSize: 17, color: 'black'}}>
                  Français
                </Text>
                {this.state.currentLanguage == 'fr' ? (
                  <>
                    {(Platform.OS == "web") ? (
                      <Image source={require('./../assets/icons/check.svg')} style={{width: 20, height: 20}} />
                    ) : (
                      <CheckIcon />
                    )}
                  </>
                ) : null}
              </TouchableOpacity>

              <TouchableOpacity
                style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 5, marginBottom: 5}}
                onPress={async () => {
                  AsyncStorage.setItem('language', 'de');
                  I18n.locale = 'de';
                  this.setState({
                    currentLanguage: 'de',
                  })
                  await userService.update_profile({
                    language: 'de'
                  });
                }}
              >
                <Text style={{fontSize: 17, color: 'black'}}>
                  Deutsch
                </Text>
                {this.state.currentLanguage == 'de' ? (
                  <>
                    {(Platform.OS == "web") ? (
                      <Image source={require('./../assets/icons/check.svg')} style={{width: 20, height: 20}} />
                    ) : (
                      <CheckIcon />
                    )}
                  </>
                ) : null}
              </TouchableOpacity>
            </View>
          ) : <View style={{marginBottom: 10}}></View>}


          {/* Change Password */}
          {(!this.state.profile.isGuest) ? (
          <>
          <TouchableOpacity style={{backgroundColor: 'white', marginTop: 10, padding: 10}}
            onPress={() => {
              if (this.state.isChangePasswordVisible) {
                this.setState({
                  old_password: '',
                  new_password: '',
                  confirm_password: '',
                });
              }
              this.setState({
                isChangePasswordVisible: !this.state.isChangePasswordVisible
              })
            }}
          >
            <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              <Text style={{fontSize: 17, color: 'black'}}>{I18n.t('change_password')}</Text>
              {this.state.isChangePasswordVisible ? (
                <>
                  {(Platform.OS == "web") ? (
                    <Image source={require('./../assets/icons/minus.svg')} style={{width: 15, height: 15}} />
                  ) : (
                    <MinusIcon />
                  )}
                </>
              ) : (
                <>
                  {(Platform.OS == "web") ? (
                    <Image source={require('./../assets/icons/right-chevron.svg')} style={{width: 15, height: 15}} />
                  ) : (
                    <RightChevronIcon />
                  )}
                </>
              )}
            </View>
          </TouchableOpacity>
          {this.state.isChangePasswordVisible ? (
            <View style={{backgroundColor: 'white', marginBottom: 10, padding: 10}}>
              
              <TextInput
                style={styles.textInput}
                onChangeText={text => this.setState({
                  old_password : text
                })}
                value={this.state.old_password}
                placeholder={I18n.t('old_password')}
                placeholderTextColor="gray"
                secureTextEntry={true}
              />
              
              <TextInput
                style={styles.textInput}
                onChangeText={text => this.setState({
                  new_password : text
                })}
                value={this.state.new_password}
                placeholder={I18n.t('new_password')}
                placeholderTextColor="gray"
                secureTextEntry={true}
              />
              {this.state.new_password.length > 0 && !this.validateOldNewPassword() ? (
                <Text style={styles.inputHelper}>{I18n.t('new_password_can_not_be_same_as_old_password')}</Text>
              ) : null}
              {(this.state.new_password.length && this.state.new_password.length < 6) ? (
                <Text style={styles.inputHelper}>{I18n.t('password_should_be_atleast_6_characters')}</Text>
              ) : null}
              
              <TextInput
                style={styles.textInput}
                onChangeText={text => this.setState({
                  confirm_password : text
                })}
                value={this.state.confirm_password}
                placeholder={I18n.t('confirm_password')}
                placeholderTextColor="gray"
                secureTextEntry={true}
              />
              {this.validatePasswords() ? null : (
                <Text style={styles.inputHelper}>{I18n.t('new_password_and_confirm_password_didnt_match')}</Text>
              )}


              <TouchableOpacity
                style={
                  this.isChangePasswordValid() ? 
                    {backgroundColor: config.primaryColor, padding: 10, borderRadius: 5} : 
                    {backgroundColor: 'gray', padding: 10, borderRadius: 5}
                }
                disabled={!this.isChangePasswordValid()}
                onPress={async () => {
                  await this.updatePassword();
                }}
              >
                <Text style={{color: 'white', textAlign: 'center', fontSize: 17, fontWeight: '700'}}>{I18n.t('change_password')}</Text>
              </TouchableOpacity>
            </View>
          ) : <View style={{marginBottom: 10}}></View>}
          
          </>
          ) : null}


          <TouchableOpacity style={{backgroundColor: 'white', marginTop: 10, padding: 10}}
            onPress={async () => {
              try {
                this.setState({
                  isModalVisible: true,
                  modal: {
                    // ...this.loading_modal_props
                    icon: 'warning',
                    title: I18n.t('are_you_sure'),
                    text: this.state.profile.isGuest ? (I18n.t('guest_account') + ' - ' + I18n.t('all_saved_data_will_be_lost_upon_logout')) : I18n.t('do_you_want_to_logout'),
                    okText: I18n.t('logout'),
                    okButtonStyle: {
                      backgroundColor: this.state.profile.isGuest ? config.dangerColor : config.primaryColor
                    },
                    okCallback: async () => {
                      // show loading modal
                      this.setState({
                        isModalVisible: true,
                        modal: {
                          ...this.loading_modal_props
                        }
                      });
                      await authService.logout();
                      this.setState({
                        isModalVisible: false
                      })
                      this.props.navigation.reset({
                        index: 0,
                        routes: [{ name: 'Home' }],
                      });
                    },
                    cancelText: I18n.t('cancel'),
                    cancelCallback: async () => {
                      this.setState({
                        isModalVisible: false
                      })
                    }
                  }
                });
              } catch (e) {
                // clear error
                this.setState({
                  isModalVisible: false
                })
              }

            }}
          >
            <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              <View>
                <Text style={{color: config.dangerColor, fontSize: 25}}>{I18n.t('logout')}</Text>
                {(this.state.profile.isGuest) ? (
                  <Text style={{color: 'gray', fontSize: 14}}>{I18n.t('guest_account')} - {I18n.t('all_saved_data_will_be_lost_upon_logout')}</Text>
                ) : null}
              </View>
              {(Platform.OS == "web") ? (
                <Image source={require('./../assets/icons/right-chevron.svg')} style={{width: 15, height: 15}} />
              ) : (
                <RightChevronIcon />
              )}
            </View>
          </TouchableOpacity>


          {/* Show only to role - user */}
          {this.state.role === config.roles.USER.key ? (
            <TouchableOpacity style={{backgroundColor: 'white', marginTop: 30, padding: 10}}
              onPress={async () => {
                try {
                  this.setState({
                    isModalVisible: true,
                    modal: {
                      // ...this.loading_modal_props
                      icon: 'warning',
                      title: I18n.t('are_you_sure'),
                      text: I18n.t('do_you_want_to_delete_account_and_erase_data_this_operation_is_irreversible_and_takes_30_days_for_completion'),
                      okText: I18n.t('delete_account'),
                      okButtonStyle: {
                        backgroundColor: config.dangerColor
                      },
                      okCallback: async () => {

                        // show loading modal
                        this.setState({
                          isModalVisible: true,
                          modal: {
                            ...this.loading_modal_props
                          }
                        });

                        let deletedResponse = await authService.deleteAccount();
                        if (deletedResponse.error) {
                          this.setState({
                            isModalVisible: true,
                            modal: {
                              icon: 'error',
                              title: I18n.t('error'),
                              text: I18n.t('something_went_wrong'),
                              okText: I18n.t('ok'),
                              okButtonStyle: {
                                backgroundColor: config.primaryColor
                              },
                              okCallback: async () => {
                                this.setState({
                                  isModalVisible: false
                                })
                              }
                            }
                          });
                          return;
                        }
                        await AsyncStorage.multiRemove([config.tokenKey, config.roleKey, config.roleValueKey]);
                        this.setState({
                          isModalVisible: false
                        })
                        this.props.navigation.reset({
                          index: 0,
                          routes: [{ name: 'Login' }],
                        });
                      },
                      cancelText: I18n.t('keep_account'),
                      cancelButtonStyle: {
                        backgroundColor: config.primaryColor
                      },
                      cancelCallback: async () => {
                        this.setState({
                          isModalVisible: false
                        })
                      }
                    }
                  });
                } catch (e) {
                  // clear error
                  this.setState({
                    isModalVisible: false
                  })
                }
              }}
            >
              <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Text style={{color: config.dangerColor, fontSize: 15}}>{I18n.t('delete_my_account_and_erase_data')}</Text>
                {(Platform.OS == "web") ? (
                  <Image source={require('./../assets/icons/right-chevron.svg')} style={{width: 15, height: 15}} />
                ) : (
                  <RightChevronIcon />
                )}
              </View>
            </TouchableOpacity>
          ) : null}



          <TouchableOpacity style={{backgroundColor: 'white', marginTop: 30, padding: 10}} disabled>
            <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              <Text style={{color: 'black'}}>{I18n.t('app_version')}</Text>
              <Text style={{color: 'black'}}>{config.CURRENT_APP_VERSION}</Text>
            </View>
          </TouchableOpacity>


          <AlertPopUpModal
            isVisible={this.state.isModalVisible}
            icon={this.state.modal.icon}
            title={this.state.modal.title}
            text={this.state.modal.text}
            okText={this.state.modal.okText}
            okCallback={this.state.modal.okCallback}
            okButtonStyle={this.state.modal.okButtonStyle ? this.state.modal.okButtonStyle : {}}
            cancelText={this.state.modal.cancelText}
            cancelCallback={this.state.modal.cancelCallback}
            cancelButtonStyle={this.state.modal.cancelButtonStyle ? this.state.modal.cancelButtonStyle : {}}
          ></AlertPopUpModal>
        </ScrollView>
        <View>
          <BottomNavigationBar {...this.props} activeTab="Profile" />
        </View>
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  scrollView: {
    backgroundColor: config.backgroundColor,
    marginHorizontal: 0,
  },
  text: {
    fontSize: 42,
  },
  textInput: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10,
    borderRadius: 5,
    padding: 5,
    color: 'black'
  },
  inputHelper: {
    marginTop: -10,
    paddingTop: 0,
    paddingBottom: 10,
    fontSize: 12,
    color: 'red'
  },
});

export default Profile;
