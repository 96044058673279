import React from "react";
import TwintDirectPay from "../components/TwintDirectPay";

class TwintDirectPayment extends React.Component {

    constructor(props) {
        super(props);
        this.role = this.props?.route?.params?.role;
        this.merchantTranId = this.props?.route?.params?.merchantTranId;
        this.orderId = this.props?.route?.params?.orderId;
        this.orderGroupId = this.props?.route?.params?.orderGroupId;
        this.token = this.props?.route?.params?.token;
        this.redirectTo = this.props?.route?.params?.redirectTo ? this.props?.route?.params?.redirectTo : 'Orders';
    }

    render() {
        if (!this.merchantTranId || !this.token) {
            // goback
            this.props.navigation.goBack();
            return (
                <></>
            )
        }
        return (
            <TwintDirectPay
                role={this.role}
                twint_direct_token={this.token}
                merchant_tran_id={this.merchantTranId}
                successCallback={() => {
                    this.props.navigation.reset({
                        index: 0,
                        routes: [{ name: this.redirectTo, params: { orderId: this.orderId, orderGroupId: this.orderGroupId, merchantTranId: this.merchantTranId } }],
                    });
                }}
                failureCallback={() => {
                    this.props.navigation.reset({
                        index: 0,
                        routes: [{ name: this.redirectTo, params: { orderId: this.orderId, orderGroupId: this.orderGroupId, merchantTranId: this.merchantTranId } }],
                    });
                }}
            />
        )
    }
}

export default TwintDirectPayment;
