import React from 'react';
import {StyleSheet, View, Text, SafeAreaView, ScrollView, StatusBar, RefreshControl, Image, Platform} from 'react-native';
import AlertPopUpModal from '../components/AlertPopUpModal';
import BottomNavigationBar from '../components/BottomNavigationBar';
import orderService from '../services/orders';
import payrexxService from '../services/payrexx';
import OrderCard from '../components/OrderCard';
import I18n from '../utils/i18n';
import config from '../config';
import TicketIcon from './../assets/icons/ticket.svg';
import moment from 'moment';
import OrderStatusSelection from '../components/OrderStatusSelection';
import RefreshButton from '../components/RefreshButton';
import LoadMoreButton from '../components/LoadMoreButton';

class OrderHistory extends React.Component {
  constructor(props) {
    super(props);

    this.orderGroupId = this.props?.route?.params?.orderGroupId;
    this.payrexxId = this.props?.route?.params?.payrexxId;
    this.payrexxReferenceId = this.props?.route?.params?.payrexxReferenceId;

    this.default_modal_props = {
      icon: '',
      title: '',
      text: '',
      okCallback: null
    }

    this.loading_modal_props = {
      ...this.default_modal_props,
      icon: 'loading',
      title: I18n.t('loading')
    }

    this.state = {
      bars: [],
      orders: [],
      totalOrdersCount: 0,
      loading: false,
      isModalVisible: false,
      limit: config.allOrderLimit,
      skip: 0,
      modal: {
        icon: '',
        title: '',
        text: '',
      },
      status: null
    }
  }

  async componentDidMount(show_loading = true) {

    this.setState({ loading: true, isModalVisible: show_loading && true, modal: this.loading_modal_props });

    this.orderGroupId = this.props?.route?.params?.orderGroupId;
    this.payrexxId = this.props?.route?.params?.payrexxId;
    this.payrexxReferenceId = this.props?.route?.params?.payrexxReferenceId;

    this.setState({ loading: false, isModalVisible: show_loading && true });
    
    if (!this.payrexxId) {
      await this.getOrders(show_loading);
    } else {
      this.setState({ loading: true, isModalVisible: true });
      let payrexxRes = await payrexxService.get_gateway(this.payrexxId);
      if (payrexxRes.error) {
        this.setState({
          modal: {
            ...this.default_modal_props,
            icon: 'error',
            title: I18n.t('error'),
            text: I18n.t(payrexxRes.error_code),
            okCallback: async () => {
              await this.getOrders(show_loading);
              this.setState({ isModalVisible: false });
            }
          }
        });
        return
      }
      if (payrexxRes.gateway.status == "confirmed" || payrexxRes.gateway.status == "completed") {
        this.setState({
          modal: {
            ...this.default_modal_props,
            icon: 'success',
            title: I18n.t('success'),
            text: I18n.t('payment_successful'),
            okCallback: async () => {
              await this.getOrders(show_loading);
              this.setState({
                loading: false,
                isModalVisible: false
              });
            }
          }
        });
      } else {
        this.setState({
          modal: {
            ...this.default_modal_props,
            icon: 'error',
            title: I18n.t('error'),
            text: I18n.t('payment_failed'),
            okCallback: async () => {
              await this.getOrders(show_loading);
              this.setState({
                loading: false,
                isModalVisible: false
              });
            }
          }
        });
      }
    }

  }

  async getOrders(show_loading, limit=config.allOrderLimit, skip=0, push=false) {

    if (push && this.state.orders.length >= this.state.totalOrdersCount) {
      return
    }

    await this.setState({
      loading: true, isModalVisible: show_loading && true, modal: this.loading_modal_props,
      limit: limit, skip: skip
    });

    let ordersRes = await orderService.get_all_orders(this.state.status, limit, skip);
    if(ordersRes.error) {
      this.setState({
        loading: false,
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('error'),
          text: ordersRes.error
        }
      });
      return
    }

    let orders = ordersRes.orders;
    let totalOrdersCount = ordersRes.count ? ordersRes.count : 0;
    if (push) {
      orders = [...this.state.orders, ...orders];
    }

    this.setState({
      orders: orders,
      totalOrdersCount: totalOrdersCount,
      isModalVisible: show_loading && false,
      loading: false
    });
  }

  async filterResults(activeStatuses) {
    await this.setState({ status: activeStatuses.join(",") });
    await this.getOrders(false);
  }

  render() {

    if (!this.state?.orders?.length) {
      return (
        <SafeAreaView style={styles.container}>
          <ScrollView
            style={styles.scrollView}
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
            refreshControl={
              <RefreshControl
                refreshing={this.state.loading}
                onRefresh={() => {
                  this.getOrders(false);
                }}
              />
            }
          >

            <View style={{ alignItems: 'center', margin: 20 }}>
              {(Platform.OS == "web") ? (
                <Image source={require('./../assets/icons/ticket.svg')} style={{ width: 100, height: 100 }} />
              ) : (
                <TicketIcon width={100} height={100} />
              )}
              <Text style={{fontSize: 30, fontWeight: '600', marginTop: 30, color: config.primaryColor}}>
                {I18n.t('no_order_tickets_yet')}
              </Text>
              {(Platform.OS == 'web') ? (
                <RefreshButton
                  onPress={() => {
                    this.getOrders(false);
                  }}
                  refreshing={this.state.loading}
                />
              ) : null}
            </View>
            <AlertPopUpModal
              isVisible={this.state.isModalVisible}
              icon={this.state.modal.icon}
              title={this.state.modal.title}
              text={this.state.modal.text}
              okCallback={this.state.modal.okCallback}
            ></AlertPopUpModal>
          </ScrollView>
          <View>
            <OrderStatusSelection onStatusChange={(activeStatuses) => this.filterResults(activeStatuses)} />
            <BottomNavigationBar {...this.props} activeTab="Orders" />
          </View>
        </SafeAreaView>
      );
    }

    return (
      <SafeAreaView style={styles.container}>
        <ScrollView style={styles.scrollView}
          refreshControl={
            <RefreshControl
              refreshing={this.state.loading}
              onRefresh={() => {
                this.getOrders(false);
              }}
            />
          }
          paginationEnabled={true}
          onEndReachedThreshold={0.5}
          onMomentumScrollEnd={(event) => {
            const isAtAnd = (parseInt(event.nativeEvent.contentOffset.y) + parseInt(event.nativeEvent.layoutMeasurement.height)) === parseInt(event.nativeEvent.contentSize.height);
            if (isAtAnd) {
              this.getOrders(false, this.state.limit, this.state.skip + this.state.limit, true);
            }
          }}
          onScrollEndDrag={(event) => {
            const isAtAnd = (parseInt(event.nativeEvent.contentOffset.y) + parseInt(event.nativeEvent.layoutMeasurement.height)) === parseInt(event.nativeEvent.contentSize.height);
            if (isAtAnd) {
              this.getOrders(false, this.state.limit, this.state.skip + this.state.limit, true);
            }
          }}
        >

          {(Platform.OS == 'web') ? (
            <RefreshButton
              onPress={() => {
                this.getOrders(false);
              }}
              refreshing={this.state.loading}
            />
          ) : null}

          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <View style={{ width: '100%' }}>
              
              {this.state.orders.map((order, index) => {
                return (
                  <OrderCard
                    key={index}
                    id={order._id}
                    history={true}
                    orderNumber={order.shortOrderNumber}
                    total={order.total}
                    currencyCode={order.currencyCode}
                    paymentType={order?.payrexxPayment?.brand ? order?.payrexxPayment?.brand : order?.paymentType}
                    status={order.status}
                    payrexxStatus={order.payrexxStatus}
                    secondary_title={order?.barId?.name}
                    description={order?.updated_at ? moment(order.updated_at).format(I18n.t('OPTIONS.timestampFormat')) : ''}
                    address={order?.address}
                    onClick={() => this.props.navigation.navigate('OrderHistoryDetails', { orderId: order._id, history: true })}
                  />
                )
              })}
              
            </View>
          </View>

          {(Platform.OS == 'web' && this.state.totalOrdersCount && (this.state.orders.length < this.state.totalOrdersCount)) ? (
            <LoadMoreButton
              onPress={() => {
                this.getOrders(false, this.state.limit, this.state.skip + this.state.limit, true);
              }}
              refreshing={this.state.loading}
            />
          ) : null}

          <AlertPopUpModal
            isVisible={this.state.isModalVisible}
            icon={this.state.modal.icon}
            title={this.state.modal.title}
            text={this.state.modal.text}
            okCallback={this.state.modal.okCallback}
          ></AlertPopUpModal>

        </ScrollView>
        <View>
          <OrderStatusSelection onStatusChange={(activeStatuses) => this.filterResults(activeStatuses)} />
          <BottomNavigationBar {...this.props} activeTab="OrderHistory" />
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  scrollView: {
    backgroundColor: config.backgroundColor,
    marginHorizontal: 0,
  },
  text: {
    fontSize: 42,
  },
});

export default OrderHistory;
