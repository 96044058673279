import config from "../config";
import { convertMessageCodeToMessage } from "../utils/common";
import I18n from './../utils/i18n';
import commonService from "./common";

const server = commonService.getAxiosInstance();


const getTwintDirectPaymentStatus = async (merchantTranId) => {
    let payment_status = null;
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.get(`/payments/twint_direct/${merchantTranId}/status`, {
            headers
        });
        payment_status = response.data;
        return payment_status;
    } catch(error) {
        console.log("Error", error.message)
        return {
            error: I18n.t('unknown_error')
        }
    }
}

export {
    getTwintDirectPaymentStatus
}
