import React from "react";
import { Text, View, TouchableOpacity, StyleSheet, Image, Platform } from "react-native";
import Modal from "react-native-modal";
import config from "../config";
import { isMiniHeight, isTooMiniHeight } from "../utils/common";

class PopUpModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: true,
            timeout: false
        }
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({
                timeout: true
            })
        }, 15000);
    }


    componentDidUpdate(nextProps, nextState) {
        nextState = {
            ...nextState,
            ...nextProps
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {

        return (
            <Modal 
                isVisible={this.props?.isVisible ? (this.props.isVisible && this.state.isModalVisible) : false}
                animationIn='pulse'
                children={this.props.children}
                style={(Platform.OS == "web") ? {
                    marginTop: isMiniHeight() ? (isTooMiniHeight() ? '0vh' : '10vh') : '18vh',
                    marginBottom: isMiniHeight() ? (isTooMiniHeight() ? '0vh' : '10vh') : '18vh',
                    backgroundColor: 'white',
                    padding: 30,
                    borderRadius: 35,
                    maxWidth: '100vw',
                    minHeight: '50%',
                    maxHeight: '100vh'
                } : {
                    marginTop: isMiniHeight() ? (isTooMiniHeight() ? undefined : '10%') : '18%',
                    marginBottom: isMiniHeight() ? (isTooMiniHeight() ? undefined : '10%') : '18%',
                    backgroundColor: 'white',
                    padding: 30,
                    borderRadius: 35,
                    minHeight: '50%',
                }}
            >
            </Modal>
        );
    }
}

export default PopUpModal;

const styles = StyleSheet.create({
    iconContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "white"
    },
    title: {
        fontSize: 25,
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'black',
    },
    text: {
        fontSize: 20,
        fontWeight: 'bold',
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center',
        color: 'black',
    },
    okButton: {
        alignItems: "center",
        justifyContent: 'center',
        backgroundColor: config.primaryColor,
        width: "100%",
        height: 50,
        justifyContent: 'center',
        borderRadius: 10,
        marginTop: 30
    },
    cancelButton: {
        alignItems: "center",
        justifyContent: 'center',
        backgroundColor: 'gray',
        width: "100%",
        height: 50,
        justifyContent: 'center',
        borderRadius: 10,
        marginTop: 30
    },
    cancelRequestButton: {
        alignItems: "center",
        justifyContent: 'center',
        backgroundColor: config.dangerColor,
        width: "100%",
        height: 50,
        justifyContent: 'center',
        borderRadius: 10,
        marginTop: 30
    }
});