import AsyncStorage from "@react-native-async-storage/async-storage";
import config from "../config";
import i18n from "../utils/i18n";
const axios = require('axios').default;

const getRequestHeaders = async () => {
    try {

        let headers = {
            'APP-VERSION': config.CURRENT_APP_VERSION,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Accept-Language': i18n.locale
        }

        let token = await AsyncStorage.getItem(config.tokenKey);
        if (token) {
            headers.token = token;
        }

        return headers;
    } catch (error) {
        return null;
    }
}

const getQueryStringFromObject = (obj) => {
    let queryString = '';
    let queryStringArray = [];
    for (const [key, value] of Object.entries(obj)) {
        queryStringArray.push(`${key}=${value}`);
    }
    queryString = queryStringArray.join('&');
    return queryString;
}

const getAxiosInstance = () => {
    const server = axios.create({
        baseURL: config.baseUrl,
        headers: {
            'APP-VERSION': config.CURRENT_APP_VERSION,
            'Accept-Language': i18n.locale
        }
    });
    return server;
}

const isTokenExists = async () => {
    try {
        let token = await AsyncStorage.getItem(config.tokenKey);
        return token ? true : false;
    } catch (error) {
        return false;
    }
}

export default {
    getRequestHeaders,
    getQueryStringFromObject,
    getAxiosInstance,
    isTokenExists
}