import { Platform } from 'react-native'
import { InAppBrowser } from 'react-native-inappbrowser-reborn'
import orderGroupsService from './../services/orderGroups'

async function openLink(url, type=null, key=null, documentId=null) {
    try {
        if (Platform.OS === 'web') {
            let paymentWindow = window.open(url, '_blank');
            // wait until the tab is closed
            while (paymentWindow.closed === false) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
            return { status: 'success' }
        }

        if (await InAppBrowser.isAvailable()) {

            if (type && key && documentId) {

                // When the in-app browser is open, android doesn't run background processes like setInterval, so we need to use web workers
                if (Platform.OS === 'android') {
                    // let worker = new Worker(URL.createObjectURL(new Blob([`
                    //     self.onmessage = async function(e) {
                    //         let keyStatus = null;
                    //         let interval = setInterval(async () => {
                    //             try {
                    //                 let response;
                    //                 if (e.data.type == 'orderGroup') {
                    //                     response = await orderGroupsService.get_order_group(e.data.documentId, { project: e.data.key });
                    //                 }
                    //                 if (response?.error) {
                    //                     clearInterval(interval);
                    //                 } else {
                    //                     if (!keyStatus) {
                    //                         keyStatus = response[e.data.type][e.data.key];
                    //                     } else {
                    //                         if (keyStatus !== response[e.data.type][e.data.key]) {
                    //                             keyStatus = response[e.data.type][e.data.key];
                    //                             self.postMessage({ status: keyStatus });
                    //                             clearInterval(interval);
                    //                         }
                    //                     }
                    //                 }
                    //             } catch (error) {
                    //                 clearInterval(interval);
                    //             }
                    //         }, 5000);
                    //     };
                    // `], { type: 'text/javascript' })));

                    // worker.postMessage({ type, key, documentId });

                    // worker.onmessage = function(e) {
                    //     if (e.data.status) {
                    //         InAppBrowser.close();
                    //         worker.terminate();
                    //     }
                    // };
                } else {
                    let keyStatus = null
                    let interval = setInterval(async () => {
                        try {
                            let response;
                            if (type == 'orderGroup') {
                                response = await orderGroupsService.get_order_group(documentId, { project: key })
                            }
                            if (response?.error) {
                                clearInterval(interval)
                            } else {
                                if (!keyStatus) {
                                    keyStatus = response[type][key];
                                } else {
                                    if (keyStatus !== response[type][key]) {
                                        keyStatus = response[type][key];
                                        InAppBrowser.close()
                                        clearInterval(interval)
                                        return { status: keyStatus }
                                    }
                                }
                            }
                        } catch (error) {
                            clearInterval(interval)
                        }
                    }, 5000)
                }
            }


            let result = await InAppBrowser.open(url,{
                forceCloseOnRedirection: false,
                showInRecents: true
            });
            return result
        } else {
            return { error: 'In App browser not found' }
        }
    } catch (error) {
        return { error: error.message }
    }
}

export {
    openLink
}
