import React, { Component } from 'react';
import {
    StyleSheet,
    View,
    TouchableOpacity,
    Text,
    Image
} from 'react-native';

import I18n from '../utils/i18n';

export default class LoadingScreen extends Component {

    constructor() {
        super();
        this.state = {
            show: false
        }
        this.timer = null;
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({
                show: true
            })
        }, 15000);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render(){

        let message = I18n.t('loading') + "...";
        
        if('message' in this.props)
            message = this.props.message;

        let message_secondary = (this.props.message_secondary && this.props.message_secondary !== "") ?
            (<Text style={styles.messageText} >
                {this.props.message_secondary}
            </Text>): null;
        return (
            <View style={styles.containerLoading}>
                {/* <Icon
                    name={"loader"}
                    style={styles.icon}
                    size={150}
                    animationName="spin"
                    animationDuration={"5000ms"}
                    animationIterationCount="infinite"
                    animationTimingFunction="linear"
                /> */}

                <Image
                    source={require('./../assets/images/loading.gif')}
                    style={{height: 250, width: 250}}
                />

                <View style={styles.textGroup}>
                    <Text style={styles.messageText} >
                        {message}
                    </Text>
                    {message_secondary}
                </View>

                {this.state.show ?
                    <TouchableOpacity style={styles.buttonCancelRequest}
                        onPress={() => {this.props.callback ? this.props.callback() : null}}
                    >
                        <Text style={[{ color: "white", fontSize: 20}]}>{I18n.t('cancel_request')}</Text>
                    </TouchableOpacity>
                    : null
                }

                {/* {setTimeout(() => {
                    return (
                        <TouchableOpacity
                            onPress={() => {this.props.callback ? this.props.callback() : null}}
                        >
                            <View style={styles.button}>
                                <Text style={styles.text}>OK</Text>
                            </View>
                        </TouchableOpacity>
                    )
                }, 20000)} */}
            </View>
        );
    }
};

const styles = StyleSheet.create({
    containerLoading: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 50,
        paddingBottom: 50,
        // borderBottomWidth: 3,
        // borderLeftWidth: 3,
        // borderRightWidth: 3,
        // borderColor: "#007198",
        // borderColor: "#ccc",
        // backgroundColor: "#ccc"
        backgroundColor: "white"
    },
    button: {
        paddingRight: 80,
        paddingLeft: 80,
        paddingTop: 20,
        paddingBottom: 20,
        borderWidth: 1.5,
        borderColor: "white",
        backgroundColor: "#ad2923"
    },
    icon:{
        color: "white",
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
    },
    textGroup:{
        marginTop: 50,
        marginBottom: 50,
    },
    messageText: {
        fontSize: 25,
        fontWeight: 'bold',
        color: "#007198",
        margin: 10,
    },
    text: {
        fontSize: 25,
        fontWeight: 'bold',
        color: "white"
    },
    buttonCancelRequest: {
        alignItems: "center",
        justifyContent: 'center',
        backgroundColor: 'rgba(192, 0, 0, 0.7)',
        width: "90%",
        height: 50,
        justifyContent: 'center',
        borderRadius: 10,
        margin: 10,
        marginTop: 30
    }
});