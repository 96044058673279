export default {
    hello: 'Hallo',
    welcome_back: 'Willkommen zurück',
    email_or_username: 'E-Mail oder Benutzername',
    password: 'Passwort',
    login: 'Anmelden',
    dont_have_an_account: 'Haben Sie kein Konto?',
    register: 'Registrieren',
    create_an_account: 'Ein Konto erstellen',
    already_have_an_account: 'Haben Sie bereits ein Konto?',
    email: 'E-Mail',
    username: 'Benutzername',
    full_name: 'Vollständiger Name',
    confirm_password: 'Passwort bestätigen',
    passwords_didnt_match: 'Passwörter stimmen nicht überein',
    invalid_email: 'Ungültige E-Mail',
    username_already_exists: 'Benutzername existiert bereits',
    registration_successful: 'Registrierung erfolgreich',
    unknown_error_occurred: 'Unbekannter Fehler aufgetreten',
    please_login_again: 'Bitte melden Sie sich erneut an',
    error: 'Fehler',
    user_not_found: 'Benutzer nicht gefunden',
    ok: 'OK',
    loading: 'Laden',
    please_wait: 'Bitte warten',
    registered_successfully: 'Erfolgreich registriert',
    add: 'Hinzufügen',
    invalid_token: 'Ungültiges Token',
    home: 'Zuhause',
    cart: 'Warenkorb',
    profile: 'Profil',
    success: 'Erfolg',
    consumptions_added_to_cart: 'Verbrauchsmaterialien zum Warenkorb hinzugefügt',
    unknown_error: 'Unbekannter Fehler',
    add_to_cart: 'In den Warenkorb',
    total_items: 'Gesamtartikel',
    total_cost: 'Gesamtkosten',
    cart_saved: 'Warenkorb gespeichert',
    cart_is_empty: 'Warenkorb ist leer',
    order_placed: 'Bestellung aufgegeben',
    orders: 'Bestellungen',
    order_id: 'Bestellnummer',
    pending: 'Ausstehend',
    confirmed: 'In Warteschlange',           // 'Confirmed' is changed to 'In Warteschlange' for better understanding
    processing: 'In Bearbeitung',
    ready: 'Bereit',
    completed: 'Abgeschlossen',
    cancelled: 'Abgebrochen',
    payment_pending: 'Zahlung ausstehend',
    payment_processing: 'Zahlung in Bearbeitung',
    payment_failed: 'Zahlung fehlgeschlagen',
    paymentPending: 'Zahlung ausstehend',
    paymentProcessing: 'Zahlung in Bearbeitung',
    paymentFailed: 'Zahlung fehlgeschlagen',
    logout: 'Ausloggen',
    no_orders_yet: 'Noch keine Bestellungen',
    no_order_tickets_yet: 'Noch keine Bestelltickets',
    no_skistations_found: 'Keine Skistationen gefunden',
    no_bars_found: 'Keine Bars gefunden',
    no_consumptions_found: 'Keine Verbrauchsmaterialien gefunden',
    go_to_cart: 'Zum Warenkorb gehen',
    view_orders: 'Bestellungen anzeigen',
    not_yet_implemented: 'Noch nicht implementiert',
    confirm_order: 'Bestellung bestätigen',
    process_order: 'Bestellung bearbeiten',
    order_ready: 'Bestellung bereit',
    complete_order: 'Bestellung abschließen',
    do_you_want_to_confirm_order: 'Möchten Sie die Bestellung bestätigen?',
    do_you_want_to_process_order: 'Möchten Sie die Bestellung bearbeiten?',
    do_you_want_to_change_status_to_ready: 'Möchten Sie den Status auf Bereit ändern?',
    do_you_want_to_complete_order: 'Möchten Sie die Bestellung abschließen?',
    yes: 'Ja',
    no: 'Nein',
    change_password: 'Passwort ändern',
    old_password: 'Altes Passwort',
    new_password: 'Neues Passwort',
    new_password_can_not_be_same_as_old_password: 'Neues Passwort kann nicht gleich wie altes Passwort sein',
    new_password_and_confirm_password_didnt_match: 'Neues Passwort und Bestätigungspasswort stimmen nicht überein',
    incorrect_old_password: 'Falsches altes Passwort',
    password_updated_successfully: 'Passwort erfolgreich aktualisiert',
    incorrect_password: 'Falsches Passwort',
    always_on_display: 'Immer auf Display',
    cannot_place_order_from_multiple_ski_stations: 'Bestellung kann nicht von mehreren Skistationen aufgegeben werden',
    save_cart: 'Warenkorb speichern',
    place_order: 'Bestellung aufgeben',
    continue_to_order: 'Weiter zur Bestellung',
    clear_cart: 'Warenkorb leeren',
    warning: 'Warnung',
    do_you_want_to_clear_cart: 'Möchten Sie den Warenkorb leeren?',
    cancel: 'Abbrechen',
    cart_is_now_empty: 'Warenkorb ist jetzt leer',
    order_number: 'Bestellnummer',
    cancel_order: 'Bestellung stornieren',
    do_you_want_to_cancel_order: 'Möchten Sie die Bestellung stornieren?',
    do_you_want_to_make_order_pending: 'Möchten Sie die Bestellung in Wartestellung setzen?',
    payment_successful: 'Zahlung erfolgreich',
    payment_failed: 'Zahlung fehlgeschlagen',
    unable_to_cancel_this_order: 'Diese Bestellung kann nicht storniert werden',
    error_retrieving_transaction: 'Fehler beim Abrufen der Transaktion',
    transaction_not_found: 'Transaktion nicht gefunden',
    gateway_not_found: 'Gateway nicht gefunden',
    order_cancelled_successfully: 'Bestellung erfolgreich storniert',
    order_not_found: 'Bestellung nicht gefunden',
    order_already_cancelled: 'Bestellung bereits storniert',
    transaction_not_found: 'Transaktion nicht gefunden',
    order_cancelled_but_an_error_occured_during_refund: 'Bestellung storniert, aber ein Fehler ist während der Rückerstattung aufgetreten',
    order_cancelled_but_unable_to_make_refund: 'Bestellung storniert, aber Rückerstattung konnte nicht durchgeführt werden',
    pay_now: 'Jetzt bezahlen',
    do_you_want_to_pay_now: 'Möchten Sie jetzt bezahlen?',
    cancel_request: 'Anfrage stornieren',
    unable_to_pay_now: 'Jetzt nicht bezahlen können',
    unable_to_pay_for_this_order: 'Kann diese Bestellung nicht bezahlen',
    are_you_sure_you_want_to_change_bar_status: 'Sind Sie sicher, dass Sie den Barstatus ändern möchten?',
    order_history: 'Bestellhistorie',
    this_app_version_is_not_supported: 'Diese App-Version wird nicht unterstützt',
    please_update_your_app: 'Bitte aktualisieren Sie Ihre App',
    app_version: 'App-Version',
    change_language: 'Sprache ändern',
    bars: 'Bars',
    consumptions: 'Verbrauch',
    order_details: 'Bestelldetails',
    order_history_details: 'Details zur Bestellhistorie',
    payment: 'Zahlung',
    password_should_be_atleast_6_characters: 'Passwort sollte mindestens 6 Zeichen haben',
    i_forgot_my_password: 'Ich habe mein Passwort vergessen',
    send_mail: 'Mail senden',
    closed: 'Geschlossen',
    refresh: 'Aktualisieren',
    refreshing: 'Aktualisierung',
    load_more: 'Mehr laden',
    step_back: 'Einen Schritt zurück',
    others: 'Andere',
    some_consumptions_are_not_added_to_cart_do_you_want_to_go_back_without_adding_them: 'Einige Verbrauchsmaterialien wurden nicht zum Warenkorb hinzugefügt. Möchten Sie zurückgehen, ohne sie hinzuzufügen?',
    one_or_more_bar_not_currently_open: 'Eine oder mehrere Bars sind derzeit nicht geöffnet',
    bar_not_currently_open: 'Bar ist derzeit nicht geöffnet',
    incorrect_username_or_password: 'Falscher Benutzername oder Passwort',
    no_order_selected: 'Keine Bestellung ausgewählt',
    go_to_orders: 'Zu den Bestellungen gehen',
    beeper_number: 'Piepsernummer',
    paid_by_cash: 'Mit Bargeld bezahlt',
    paid_by_card: 'Mit Karte bezahlt',
    order_cancelled_please_refund_the_client: 'Bestellung storniert. Bitte erstatten Sie dem Kunden',
    printer_ip: 'Drucker IP',
    mark_as_completed: 'Als abgeschlossen markieren',
    in_progress: 'In Bearbeitung',
    daily_report: 'Tagesbericht',
    actual_turnover: 'Tatsächlicher Umsatz',
    expected_turnover: 'Erwarteter Umsatz',
    generate_report: 'Bericht erstellen',
    cash_turnover: 'Bargeldumsatz',
    card_turnover: 'Kartenumsatz',
    password_changed: 'Passwort geändert',
    cash: 'Bargeld',
    card: 'Karte',
    online: 'Online',
    quick_order: 'Schnellbestellung',
    delete_my_account_and_erase_data: 'Mein Konto löschen und Daten löschen',
    are_you_sure: 'Bist du sicher?',
    do_you_want_to_delete_account_and_erase_data_this_operation_is_irreversible_and_takes_30_days_for_completion: 'Möchten Sie das Konto löschen und die Daten löschen? Dieser Vorgang ist unwiderruflich und dauert 30 Tage bis zur Fertigstellung',
    delete_account: 'Konto löschen',
    keep_account: 'Konto behalten',
    something_went_wrong: 'Etwas ist schief gelaufen',
    email_already_exists: 'E-Mail existiert bereits',
    order_groups: 'Bestellgruppen',
    order_group: 'Bestellgruppe',
    order_group_id: 'Bestellgruppen-ID',
    order_group_details: 'Details zur Bestellgruppe',
    no_order_groups_yet: 'Noch keine Bestellgruppen',
    id: 'ID',
    network_error: 'Netzwerkfehler',
    table: 'Tisch',
    or: 'oder',
    continue_with_phone_number: 'Mit Telefonnummer fortfahren',
    continue_with_email: 'Mit E-Mail fortfahren',
    phone_number: 'Telefonnummer',
    invalid_phone_number: 'Ungültige Telefonnummer',
    invalid_otp: 'Ungültiges OTP',
    invalid_code: 'Ungültiger Code',
    send_otp: 'OTP senden',
    send_code: 'Code senden',
    otp: 'OTP',
    code: 'Code',
    otp_sent_to: 'OTP gesendet an',
    code_sent_to: 'Code gesendet an',
    resend_otp_in: 'OTP erneut senden in',
    resend_code_in: 'Code erneut senden in',
    seconds: 'Sekunden',
    resend_otp: 'OTP erneut senden',
    resend_code: 'Code erneut senden',
    validate: 'Validieren',
    edit: 'Bearbeiten',
    otp_should_be_3_characters: 'OTP sollte 3 Zeichen sein',
    code_should_be_3_characters: 'Code sollte 3 Zeichen sein',
    bar_id_is_required: 'Bar-ID ist erforderlich',
    failed: 'Fehlgeschlagen',
    online_payment: 'Online-Zahlung',
    refunded: 'Erstattet',
    do_you_want_to_pay_by_cash: 'Möchten Sie bar bezahlen?',
    do_you_want_to_pay_by_card: 'Möchten Sie mit Karte bezahlen?',
    do_you_want_to_receive_payment_online: 'Möchten Sie die Zahlung online erhalten?',
    do_you_want_to_receive_payment_by_cash: 'Möchten Sie die Zahlung bar erhalten?',
    do_you_want_to_receive_payment_by_card: 'Möchten Sie die Zahlung per Karte erhalten?',
    do_you_want_to_cancel_all_orders: 'Möchten Sie alle Bestellungen stornieren?',
    order_group_cancelled_and_refunded_successfully: 'Bestellung storniert und erfolgreich erstattet',
    order_tickets: 'Bestellscheine',
    order_ticket_details: 'Details zum Bestellschein',
    order_group_cancelled_please_refund_the_client: 'Bestellung storniert. Bitte erstatten Sie dem Kunden',
    kot: 'KOT',
    table_number: 'Tischnummer',
    scan_qr_code: 'QR-Code scannen',
    order_ticket_history: 'Bestellscheinverlauf',
    order_ticket_history_details: 'Details zum Bestellscheinverlauf',
    update_cart: 'Warenkorb aktualisieren',
    save_to_cart: 'In den Warenkorb speichern',
    go_to_cart_and_clear_to_order_from_another_bar: 'Gehen Sie zum Warenkorb und leeren Sie ihn, um von einer anderen Bar zu bestellen',
    country_code: 'Ländercode',
    invalid_country_code: 'Ungültiger Ländercode',
    scan_and_order: 'Scannen & bestellen',
    scan_and_pay: 'Scannen & bezahlen',
    no_order_found: 'Keine Bestellung gefunden',
    unable_to_pay_at_the_moment: 'Zurzeit nicht zahlungsfähig',
    you_can_only_add_items_from_one_bar_at_a_time: 'Sie können nur Artikel von einer Bar auf einmal hinzufügen',
    currently_not_available: 'Derzeit nicht verfügbar',
    available_from: 'Verfügbar von',
    available_to: 'Verfügbar bis',
    to: 'zu',
    available_between: 'Verfügbar zwischen',
    and: 'und',
    waiting: 'Warten',
    paid: 'Bezahlt',
    waiting_for_payment: 'Warten auf Zahlung',
    payment_failed: 'Zahlung fehlgeschlagen',
    to_be_paid: 'Zu zahlen',
    app_version_missing: 'App-Version fehlt',
    app_version_too_old: 'App-Version zu alt',
    reload: 'Neu laden',
    bar_is_closed_permanantly: 'Bar ist dauerhaft geschlossen',
    beeper: 'Piepser',
    invalid_fullname: 'Ungültiger vollständiger Name',
    unauthorized: 'Unberechtigt',
    report_generated: 'Bericht erstellt',
    continue: 'Fortsetzen',
    guest_account: 'Gastkonto',
    all_saved_data_will_be_lost_upon_logout: 'Alle gespeicherten Daten gehen beim Abmelden verloren',
    do_you_want_to_logout: 'Möchten Sie sich abmelden?',
    continue_without_an_account: 'Ohne Konto fortfahren',
    please_enable_notification_permission_to_get_updates_on_your_orders: 'Bitte aktivieren Sie die Benachrichtigungsberechtigung, um Updates zu Ihren Bestellungen zu erhalten',
    new: 'Neu',
    please_close_this_tab: 'Bitte schließen Sie diesen Tab',
    payment_successful: 'Zahlung erfolgreich',
    payment_failed: 'Zahlung fehlgeschlagen',
    payment_cancelled: 'Zahlung abgebrochen',
    order_group_already_checked_out: 'Bestellung bereits ausgecheckt',
    app_version_too_old: 'App-Version zu alt',
    unable_to_save_hidden_bar_locally: 'Versteckte Bar kann nicht lokal gespeichert werden',
    unable_to_remove_hidden_bar_locally: 'Versteckte Bar kann nicht lokal entfernt werden',
    unable_to_get_local_hidden_bars: 'Lokale versteckte Bars können nicht abgerufen werden',
    added_from_scanned_qr: 'Hinzugefügt von gescanntem QR',
    failed_to_create_payment_gateway: 'Zahlungsgateway konnte nicht erstellt werden',
    order_updates: 'Bestellaktualisierungen',
    please_scan_the_following_twint_qr_code: "Bitte scannen Sie den folgenden Twint-QR-Code",
    switch_to_the_app_now: 'Jetzt zur App wechseln',
    pay_and_place_order: 'Bezahlen und Bestellung aufgeben',
    pay_for_full_order: 'Für die gesamte Bestellung bezahlen',
    pay_for_this_order_ticket: 'Für diesen Bestellschein bezahlen',
    tickets: 'Tickets',
    paid_by_twint: 'Mit Twint bezahlt',
    copy_code: 'Code kopieren',
    copied: 'Kopiert',
    copied_to_clipboard: 'In die Zwischenablage kopiert',
    enter_this_code_on_your_twint_app: 'Geben Sie diesen Code in Ihrer Twint-App ein',
    payment_in_progress: 'Zahlung in Bearbeitung',
    cancel_transaction: 'Transaktion abbrechen',
    pay_by_card: 'Mit Karte bezahlen',
    pay_by_twint: 'Mit Twint bezahlen',
    twint: 'Twint',
    twint_not_enabled: 'Twint nicht aktiviert',
    payrexx_details_not_found: 'Payrexx-Details nicht gefunden',
    pay_for_full_order_by_card: 'Für die gesamte Bestellung mit Karte bezahlen',
    pay_for_full_order_by_twint: 'Für die gesamte Bestellung mit Twint bezahlen',
    do_you_want_to_receive_payment_by_twint: 'Möchten Sie die Zahlung per Twint erhalten?',
    pay_later: 'Später bezahlen',
    do_you_want_to_receive_payment_later: 'Möchten Sie die Zahlung später erhalten?',
    there_are: 'Es gibt',
    orders_left_before_yours: 'Bestellungen vor Ihrer',
    country_list: {
        switzerland: 'Schweiz',
        germany: 'Deutschland',
        france: 'Frankreich',
        italy: 'Italien',
        united_kingdom: 'Vereinigtes Königreich',
        united_states: 'Vereinigte Staaten',
        india: 'Indien'
    },
    SENTENCE: {
        forgot_password_text: 'Bitte senden Sie uns eine E-Mail an info@jm-contactless.ch mit Ihren Kontaktdaten und wir helfen Ihnen, Ihr Konto wiederherzustellen',
        card_payment_in_progress_text: 'Anfrage an Terminal gesendet, bitte klicken Sie auf die Schaltfläche "Häkchen" am Terminal',
    },
    OPTIONS: {
        timestampFormat: 'DD/MM/YYYY HH:mm',
        timeFormat: 'HH:mm',
    },
    LOCATION_ERROR: {
        PERMISSION_DENIED: 'Standortberechtigung verweigert',
        PERMISSION_DENIED_INFO: 'Bitte aktivieren Sie die Standortberechtigung, um Bars in Ihrer Nähe zu finden',
        PERMISSION_DENIED_SECONDARY_INFO: 'Klicken Sie zum Aktivieren',
        POSITION_UNAVAILABLE: 'Position nicht verfügbar',
        PERMISSION_UNAVAILABLE_INFO: 'Bitte laden Sie die Seite neu, um es erneut zu versuchen',
        TIMEOUT: 'Zeitüberschreitung',
        UNKNOWN_ERROR: 'Unbekannter Fehler'
    },
};