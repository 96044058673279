import AsyncStorage from '@react-native-async-storage/async-storage';
import commonService from './common';

const server = commonService.getAxiosInstance();

const get_cart = async () => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.get(`/cart`, {
            headers
        });
        return response.data;
    } catch (error) {
        return error?.response?.data ? error.response.data : { error: 'Network error', error_code: '|network_error|' };
    }
}

const get_cart_details = async () => {

    let localCart = await AsyncStorage.getItem('cart');

    if (!localCart || localCart === 'undefined' || localCart === 'null' || localCart === '[]' || localCart === '{}') {
        localCart = [];
    } else {
        localCart = JSON.parse(localCart);
    }

    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.post(`/cart/details`, localCart, {
            headers
        });
        localCart = response.data;
    } catch (error) {
        return error?.response?.data ? error.response.data : { error: 'Network error', error_code: '|network_error|' };
    }

    return localCart;
}

const get_local_cart = async () => {
    try {

        let localCart = await AsyncStorage.getItem('cart');

        if (!localCart || localCart === 'undefined' || localCart === 'null' || localCart === '[]' || localCart === '{}') {
            return [];
        }

        let headers = await commonService.getRequestHeaders();
        const response = await server.post(`/cart/get_local_cart`, localCart, {
            headers
        });

        if (response?.data?.error) {
            return response.data;
        }
        put_local_cart(response.data.cart);
        return response.data.cart;
    } catch (error) {
        return error?.response?.data ? error.response.data : { error: 'Network error', error_code: '|network_error|' };
    }
}

const add_consumptions_to_cart = async (payload) => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.post(`/cart`, payload, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const add_consumptions_to_local_cart = async (payload) => {
    try {
        let localCart = AsyncStorage.getItem('cart');
        if (!localCart || localCart === 'undefined' || localCart === 'null' || localCart === '[]' || localCart === '{}') {
            localCart = [];
        }
        
        localCart = JSON.parse(localCart);

        let itemIndex = localCart.findIndex(item => item.consumption === payload.consumption);
        if (itemIndex > -1) {
            localCart[itemIndex].quantity = localCart[itemIndex].quantity + payload.quantity;
        } else {
            localCart.push(payload);
        }

        AsyncStorage.setItem('cart', JSON.stringify(localCart));
        return localCart;
    } catch (error) {
        return error.response.data;
    }
}

const put_cart = async (payload) => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.put(`/cart`, payload, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const put_local_cart = async (payload) => {
    try {
        AsyncStorage.setItem('cart', JSON.stringify(payload));
    } catch (error) {
        return error.response.data;
    }
}

const delete_cart = async () => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.delete(`/cart`, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const delete_local_cart = async () => {
    try {
        AsyncStorage.removeItem('cart');
    } catch (error) {
        return error.response.data;
    }
}

export default {
    get_cart,
    get_cart_details,
    get_local_cart,
    add_consumptions_to_cart,
    add_consumptions_to_local_cart,
    put_cart,
    put_local_cart,
    delete_cart,
    delete_local_cart
}