import AsyncStorage from '@react-native-async-storage/async-storage';
import config from '../config';

const keys = {
    HIDDEN_BARS: config.hiddenBarsKey,
    TOKEN: config.tokenKey,
    LOCALE: config.localeKey,
    ROLE: config.roleKey,
    ROLE_VALUE: config.roleValueKey,
    BARS: config.barsKey,
    FCM_TOKEN: config.fcmTokenKey,
}


const getItem = async (key) => {
    return await AsyncStorage.getItem(key);
}

const setItem = async (key, value) => {
    await AsyncStorage.setItem(key, value);
    return true;
}

const removeItem = async (key) => {
    await AsyncStorage.removeItem(key);
    return true;
}

const clear = async () => {
    await AsyncStorage.clear();
    return true;
}

// hidden_bars
const getHiddenBars = async () => {
    let hiddenBars = await getItem(keys.HIDDEN_BARS);
    if (!hiddenBars) {
        return [];
    }
    return JSON.parse(hiddenBars);
}

const setHiddenBars = async (hiddenBars) => {
    return await setItem(keys.HIDDEN_BARS, JSON.stringify(hiddenBars));
}

const removeHiddenBars = async () => {
    return await removeItem(keys.HIDDEN_BARS);
}

// Locale
const getLocale = async () => {
    return await getItem(keys.LOCALE);
}

const setLocale = async (locale) => {
    return await setItem(keys.LOCALE, locale);
}

const removeLocale = async () => {
    return await removeItem(keys.LOCALE);
}

// Token
const getToken = async () => {
    return await getItem(keys.TOKEN);
}

const setToken = async (token) => {
    return await setItem(keys.TOKEN, token);
}

const removeToken = async () => {
    return await removeItem(keys.TOKEN);
}

export default {
    getItem,
    setItem,
    removeItem,
    clear,
    getHiddenBars,
    setHiddenBars,
    removeHiddenBars,
    getLocale,
    setLocale,
    removeLocale,
    getToken,
    setToken,
    removeToken,
}