import React from "react";
import CardPay from "../components/CardPay";
import { cancelPayment } from "../services/myposCard";

class CardPayment extends React.Component {

    constructor(props) {
        super(props);
        this.role = this.props?.route?.params?.role || this.props?.role;
        this.ruid = this.props?.route?.params?.ruid || this.props?.ruid;
        this.orderId = this.props?.route?.params?.orderId || this.props?.orderId;
        this.orderGroupId = this.props?.route?.params?.orderGroupId || this.props?.orderGroupId;
        this.redirectTo = (this.props?.route?.params?.redirectTo || this.props?.redirectTo) || 'OrderGroups';
    }

    render() {
        if (!this?.ruid) {
            return (
                <></>
            )
        }
        return (
            <CardPay
                role={this.role}
                ruid={this?.ruid}
                successCallback={() => {
                    this.props.navigation.reset({
                        index: 0,
                        routes: [{ name: this.redirectTo, params: { orderId: this.orderId, orderGroupId: this.orderGroupId, ruid: this.ruid } }],
                    });
                }}
                failureCallback={() => {
                    this.props.navigation.reset({
                        index: 0,
                        routes: [{ name: this.redirectTo, params: { orderId: this.orderId, orderGroupId: this.orderGroupId, ruid: this.ruid } }],
                    });
                }}
                cancelCallback={async () => {
                    await cancelPayment(this?.ruid);
                    this.props.navigation.reset({
                        index: 0,
                        routes: [{ name: this.redirectTo, params: { orderId: this.orderId, orderGroupId: this.orderGroupId, ruid: this.ruid } }],
                    });
                }}
            />
        )
    }
}

export default CardPayment;
