import commonService from './common';

const server = commonService.getAxiosInstance();

const get_available_payment_options = async (query={}) => {
    try {
        let headers = await commonService.getRequestHeaders();
        let queryString = commonService.getQueryStringFromObject(query);
        const response = await server.get(`/payments/availablePaymentOptions?${queryString}`, {
            headers
        });
        return response.data;
    } catch (error) {
        return error?.response?.data ? error.response.data : { error: 'Network error', error_code: '|network_error|'};
    }
}

export default {
    get_available_payment_options
}
