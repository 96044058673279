import React from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import config from "../config";

class BankList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            banks: [
                {
                    name: "TWINT - autre banque & prepaid",
                    code: 'twint-issuer1://',
                    selected: props?.selectedBank === 'twint-issuer1://' ? true : false
                },
                // <MenuItem value="twint-issuer25://">AEK TWINT</MenuItem>
                {
                    name: "AEK TWINT",
                    code: 'twint-issuer25://',
                    selected: props?.selectedBank === 'twint-issuer25://' ? true : false
                },
                // <MenuItem value="twint-issuer30://">AKB TWINT</MenuItem>
                {
                    name: "AKB TWINT",
                    code: 'twint-issuer30://',
                    selected: props?.selectedBank === 'twint-issuer30://' ? true : false
                },
                // <MenuItem value="twint-issuer12://">AppKB TWINT</MenuItem>
                {
                    name: "AppKB TWINT",
                    code: 'twint-issuer12://',
                    selected: props?.selectedBank === 'twint-issuer12://' ? true : false
                },
                // <MenuItem value="twint-issuer16://">BCF TWINT</MenuItem>
                {
                    name: "BCF TWINT",
                    code: 'twint-issuer16://',
                    selected: props?.selectedBank === 'twint-issuer16://' ? true : false
                },
                // <MenuItem value="twint-issuer10://">BCGE TWINT</MenuItem>
                {
                    name: "BCGE TWINT",
                    code: 'twint-issuer10://',
                    selected: props?.selectedBank === 'twint-issuer10://' ? true : false
                },
                // <MenuItem value="twint-issuer17://">BCJ TWINT</MenuItem>
                {
                    name: "BCJ TWINT",
                    code: 'twint-issuer17://',
                    selected: props?.selectedBank === 'twint-issuer17://' ? true : false
                },
                // <MenuItem value="twint-issuer18://">BCN TWINT</MenuItem>
                {
                    name: "BCN TWINT",
                    code: 'twint-issuer18://',
                    selected: props?.selectedBank === 'twint-issuer18://' ? true : false
                },
                // <MenuItem value="twint-issuer5://">BCV TWINT</MenuItem>
                {
                    name: "BCV TWINT",
                    code: 'twint-issuer5://',
                    selected: props?.selectedBank === 'twint-issuer5://' ? true : false
                },
                // <MenuItem value="twint-issuer13://">BCVs TWINT</MenuItem>
                {
                    name: "BCVs TWINT",
                    code: 'twint-issuer13://',
                    selected: props?.selectedBank === 'twint-issuer13://' ? true : false
                },
                // <MenuItem value="twint-issuer23://">BEKB TWINT</MenuItem>
                {
                    name: "BEKB TWINT",
                    code: 'twint-issuer23://',
                    selected: props?.selectedBank === 'twint-issuer23://' ? true : false
                },
                // <MenuItem value="twint-issuer26://">BLKB TWINT</MenuItem>
                {
                    name: "BLKB TWINT",
                    code: 'twint-issuer26://',
                    selected: props?.selectedBank === 'twint-issuer26://' ? true : false
                },
                // <MenuItem value="twint-issuer33://">Baloise TWINT</MenuItem>
                {
                    name: "Baloise TWINT",
                    code: 'twint-issuer33://',
                    selected: props?.selectedBank === 'twint-issuer33://' ? true : false
                },
                // <MenuItem value="twint-issuer4://">Credit Suisse TWINT</MenuItem>
                {
                    name: "Credit Suisse TWINT",
                    code: 'twint-issuer4://',
                    selected: props?.selectedBank === 'twint-issuer4://' ? true : false
                },
                // <MenuItem value="twint-issuer15://">GKB TWINT</MenuItem>
                {
                    name: "GKB TWINT",
                    code: 'twint-issuer15://',
                    selected: props?.selectedBank === 'twint-issuer15://' ? true : false
                },
                // <MenuItem value="twint-issuer22://">GLKB TWINT</MenuItem>
                {
                    name: "GLKB TWINT",
                    code: 'twint-issuer22://',
                    selected: props?.selectedBank === 'twint-issuer22://' ? true : false
                },
                // <MenuItem value="twint-issuer32://">LUKB TWINT</MenuItem>
                {
                    name: "LUKB TWINT",
                    code: 'twint-issuer32://',
                    selected: props?.selectedBank === 'twint-issuer32://' ? true : false
                },
                // <MenuItem value="twint-issuer21://">Migros Bank TWINT</MenuItem>
                {
                    name: "Migros Bank TWINT",
                    code: 'twint-issuer21://',
                    selected: props?.selectedBank === 'twint-issuer21://' ? true : false
                },
                // <MenuItem value="twint-issuer29://">NKB TWINT</MenuItem>
                {
                    name: "NKB TWINT",
                    code: 'twint-issuer29://',
                    selected: props?.selectedBank === 'twint-issuer29://' ? true : false
                },
                // <MenuItem value="twint-issuer8://">OKB TWINT</MenuItem>
                {
                    name: "OKB TWINT",
                    code: 'twint-issuer8://',
                    selected: props?.selectedBank === 'twint-issuer8://' ? true : false
                },
                // <MenuItem value="twint-issuer7://">PostFinance TWINT</MenuItem>
                {
                    name: "PostFinance TWINT",
                    code: 'twint-issuer7://',
                    selected: props?.selectedBank === 'twint-issuer7://' ? true : false
                },
                // <MenuItem value="twint-issuer28://">Radicant TWINT</MenuItem>
                {
                    name: "Radicant TWINT",
                    code: 'twint-issuer28://',
                    selected: props?.selectedBank === 'twint-issuer28://' ? true : false
                },
                // <MenuItem value="twint-issuer6://">Raiffeisen TWINT</MenuItem>
                {
                    name: "Raiffeisen TWINT",
                    code: 'twint-issuer6://',
                    selected: props?.selectedBank === 'twint-issuer6://' ? true : false
                },
                // <MenuItem value="twint-issuer14://">SGKB TWINT</MenuItem>
                {
                    name: "SGKB TWINT",
                    code: 'twint-issuer14://',
                    selected: props?.selectedBank === 'twint-issuer14://' ? true : false
                },
                // <MenuItem value="twint-issuer24://">SHKB TWINT</MenuItem>
                {
                    name: "SHKB TWINT",
                    code: 'twint-issuer24://',
                    selected: props?.selectedBank === 'twint-issuer24://' ? true : false
                },
                // <MenuItem value="twint-issuer31://">SZKB TWINT</MenuItem>
                {
                    name: "SZKB TWINT",
                    code: 'twint-issuer31://',
                    selected: props?.selectedBank === 'twint-issuer31://' ? true : false
                },
                // <MenuItem value="twint-issuer27://">Swissquote TWINT</MenuItem>
                {
                    name: "Swissquote TWINT",
                    code: 'twint-issuer27://',
                    selected: props?.selectedBank === 'twint-issuer27://' ? true : false
                },
                // <MenuItem value="twint-issuer19://">TKB TWINT</MenuItem>
                {
                    name: "TKB TWINT",
                    code: 'twint-issuer19://',
                    selected: props?.selectedBank === 'twint-issuer19://' ? true : false
                },
                // <MenuItem value="twint-issuer2://">UBS TWINT</MenuItem>
                {
                    name: "UBS TWINT",
                    code: 'twint-issuer2://',
                    selected: props?.selectedBank === 'twint-issuer2://' ? true : false
                },
                // <MenuItem value="twint-issuer34://">UKB TWINT</MenuItem>
                {
                    name: "UKB TWINT",
                    code: 'twint-issuer34://',
                    selected: props?.selectedBank === 'twint-issuer34://' ? true : false
                },
                // <MenuItem value="twint-issuer20://">Valiant TWINT</MenuItem>
                {
                    name: "Valiant TWINT",
                    code: 'twint-issuer20://',
                    selected: props?.selectedBank === 'twint-issuer20://' ? true : false
                },
                // <MenuItem value="twint-issuer3://">ZKB TWINT</MenuItem>
                {
                    name: "ZKB TWINT",
                    code: 'twint-issuer3://',
                    selected: props?.selectedBank === 'twint-issuer3://' ? true : false
                },
                // <MenuItem value="twint-issuer9://">ZugerKB TWINT</MenuItem>
                {
                    name: "ZugerKB TWINT",
                    code: 'twint-issuer9://',
                    selected: props?.selectedBank === 'twint-issuer9://' ? true : false
                },
                // <MenuItem value="twint-issuer35://">yuh TWINT</MenuItem>
                {
                    name: "yuh TWINT",
                    code: 'twint-issuer35://',
                    selected: props?.selectedBank === 'twint-issuer35://' ? true : false
                }
            ]
        }

    }

    render() {

        return (
            <View style={{height: '60%', maxHeight: 250}}>
                <ScrollView style={{borderColor: 'grey', borderWidth: 1, borderRadius: 10}}>
                    {this.state.banks.map((bank) => (
                        <View key={bank.code}>
                            <TouchableOpacity
                                onPress={() => {
                                    if(this.props.onBankChange)
                                        this.props.onBankChange(bank?.code);
                                }}
                            >
                                <View style={(bank?.code == this.props?.selectedBank) ? styles.selectedView : styles.view}>
                                    <Text style={(bank?.code == this.props?.selectedBank) ? styles.selectedText : styles.text}>{bank.name}</Text>
                                </View>
                            </TouchableOpacity>
                            <View style={{borderBottomColor: 'grey', borderBottomWidth: 1}}></View>
                        </View>
                    ))}
                </ScrollView>
            </View>
        );
    }
}

const styles = {
    selectedView: {
        flexDirection: 'row', justifyContent: 'space-between', padding: 10, backgroundColor: config.primaryColor, margin: 5, borderRadius: 10
    },
    view: {
        flexDirection: 'row', justifyContent: 'space-between', padding: 10, backgroundColor: 'white', margin: 5, borderRadius: 10
    },
    selectedText: {
        fontSize: 20, color: 'white', fontWeight: 'bold'
    },
    text: {
        fontSize: 20, color: 'gray', fontWeight: 'bold'
    }
}

export default BankList;