import React from 'react';
import I18n from '../utils/i18n';
import { View, Text, Image, Platform, TouchableOpacity, Linking } from 'react-native';
import { checkNotificationEnabled, requestNotificationPermission, requestUserPermission } from '../utils/pushNotificationHelper';

class NotificationCheck extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            notificationError: null
        }
    }

    async componentDidMount() {
        try {
            // let notificationEnabled = await requestUserPermission();
            let notificationEnabled = await checkNotificationEnabled();
            this.setState({
                notificationError: !notificationEnabled
            })
        } catch (error) {
            this.setState({
                notificationError: true
            });
        }
    }

    render() {

        if(!this.state.notificationError) {
            return null;
        }

        return (
            <TouchableOpacity
                style={{ alignItems: 'center', paddingTop: 5, paddingBottom: 5, borderBottomColor: 'red', borderBottomWidth: 2, justifyContent: 'space-around', flexDirection: 'row'}}
                onPress={async () => {
                    let enabled = await requestNotificationPermission();
                    // open notification settings
                    if(!enabled) {
                        if (Platform.OS === 'ios') {
                            Linking.openURL('App-Prefs:NOTIFICATIONS_ID')
                        } else if (Platform.OS === 'android') {
                            Linking.openSettings();
                        }
                    }
                    this.setState({
                        notificationError: !enabled
                    });
                }}
            >
                <View style={{alignItems: 'center', paddingHorizontal: 30}}>
                    <Text style={{ fontSize: 16, fontWeight: '400', color: 'black', textAlign: 'center' }}>{I18n.t('please_enable_notification_permission_to_get_updates_on_your_orders')}</Text>
                </View>
            </TouchableOpacity>
        );
    }

}

export default NotificationCheck;