import config from '../config';
import commonService from './common';

const server = commonService.getAxiosInstance();

const get_orders = async (status = null, limit=config.orderLimit, skip=0, quick_order=null, reverse=null) => {
    try {
        let headers = await commonService.getRequestHeaders();
        let url = `/orders?limit=${limit}&skip=${skip}`;
        if(status) {
            url += `&status=${status}`;
        }
        if(quick_order) {
            url += `&quick_order=${quick_order}`;
        }
        if(reverse) {
            url += `&reverse=${reverse}`;
        }

        const response = await server.get(url, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const get_completed_orders = async (status = null, limit=config.orderLimit, skip=0) => {
    try {
        let headers = await commonService.getRequestHeaders();
        let url = `/orders/completed?limit=${limit}&skip=${skip}`;
        if(status) {
            url += `&status=${status}`;
        }

        const response = await server.get(url, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const get_cancelled_orders = async (status = null, limit=config.orderLimit, skip=0) => {
    try {
        let headers = await commonService.getRequestHeaders();
        let url = `/orders/cancelled?limit=${limit}&skip=${skip}`;
        if(status) {
            url += `&status=${status}`;
        }

        const response = await server.get(url, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}



const get_all_orders = async (status = null, limit=config.orderLimit, skip=0) => {
    try {
        let headers = await commonService.getRequestHeaders();
        let url = `/orders/all?limit=${limit}&skip=${skip}`;
        if(status) {
            url += `&status=${status}`;
        }

        const response = await server.get(url, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const get_order = async (id) => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.get(`/orders/${id}`, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const place_order = async (payload={}) => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.post(`/orders`, payload, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const place_order_from_local_cart = async (payload={}) => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.post(`/orders/local`, payload, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const update_order = async (id, payload) => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.patch(`/orders/${id}`, payload, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const cancel_order = async (order_id) => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.post(`/orders/${order_id}/cancel`, {}, {
            headers
        });
        return response.data;
    } catch (error) {
        console.log(error.message)
        return error.response.data;
    }
}

const pay_now = async (order_id, payment_type=config.paymentType.ONLINE.key, payment_method=config.paymentMethod.PAYREXX.key) => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.post(`/orders/${order_id}/pay`, {
            paymentType: payment_type,
            paymentMethod: payment_method
        }, {
            headers
        });
        return response.data;
    } catch (error) {
        console.log(error.message)
        return error.response.data;
    }
}

export default {
    get_orders,
    get_completed_orders,
    get_cancelled_orders,
    get_all_orders,
    get_order,
    place_order,
    place_order_from_local_cart,
    update_order,
    cancel_order,
    pay_now
}