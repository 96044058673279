import React from 'react';
import {StyleSheet, View, Text, SafeAreaView, ScrollView, StatusBar, RefreshControl, TouchableOpacity, Image, Platform} from 'react-native';
import AlertPopUpModal from '../components/AlertPopUpModal';
import BottomNavigationBar from '../components/BottomNavigationBar';
import ItemHorizontal from '../components/ItemHorizontal';
import config from '../config';
import barsService from '../services/bars';
import cartService from '../services/cart';
import { convertMessageCodeToMessage, generateAssetLink } from '../utils/common';
import I18n from '../utils/i18n';
import BarAddressIcon from './../assets/icons/address_home.svg';
import { useIsFocused } from '@react-navigation/native';
import RefreshButton from '../components/RefreshButton';
import commonStyles from './../styles/common';
import LoadMoreButton from '../components/LoadMoreButton';
import Geolocation from '@react-native-community/geolocation';
import LocationError from '../components/LocationError';
import ScanTableQrCode from '../components/ScanTableQrCode';
import commonService from '../services/common';
import LocationCheck from '../components/LocationError';

class Bars extends React.Component {
  constructor(props) {
    super(props);

    this.activeTab = this.props?.activeTab;

    this.default_modal_props = {
      icon: '',
      title: '',
      text: '',
      okCallback: null
    }

    this.loading_modal_props = {
      ...this.default_modal_props,
      icon: 'loading',
      title: I18n.t('loading')
    }

    this.state = {
      bars: [],
      cartItems: [],
      localCartItems: [],
      loading: false,
      isModalVisible: false,
      limit: config.paginationLimit,
      skip: 0,
      modal: {
        icon: '',
        title: '',
        text: '',
      },
      locationError: null,
      location: null
    }
  }

  componentDidUpdate(nextProps, nextState) {
    nextState = {
        ...nextState,
        ...nextProps
    }
  }

  async componentDidMount(show_loading = true) {

    let query = {
      // skiStation: this.ski_station_id
    };
    // if (this.props?.skiStation) {
    //   query.skiStation = this.props.skiStation;
    // }
    await this.getBars(query, { show_loading: show_loading });

    this.updateCartDetails();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.isFocused !== this.props.isFocused) {
      if (this.props.isFocused) {
        this.updateCartDetails();
      }
    }
  }

  async updateCartDetails() {

    let cartRes;
    let tokenExists = await commonService.isTokenExists();
    if(tokenExists) {
      cartRes = await cartService.get_cart();
      if (cartRes.error) {
        return;
      }

      let cartItemsClone = JSON.parse(JSON.stringify(cartRes.cart?.items ? cartRes.cart.items : []));
      this.setState({
        cartItems: cartRes.cart?.items ? cartRes.cart.items : [],
        localCartItems: cartItemsClone
      });
    }
  }

  async getBars(query={}, options={}) {

    let default_query = {limit: config.paginationLimit, skip: 0};

    // default query update to include hidden bars if scanned
    let hidden_bars = await barsService.get_local_hidden_bars();
    if (hidden_bars && hidden_bars.length) {
      hidden_bars = hidden_bars.join(',');
      query.includeHiddenBars = hidden_bars;
    }

    let default_options = {show_loading: true, show_small_loading: true, push: false};
    
    query = {...default_query, ...query};
    options = {...default_options, ...options};

    if (options.push && this.state.bars.length >= this.state.totalBarsCount) {
      return
    }
    
    await this.setState({
      loading: options.show_small_loading, isModalVisible: options.show_loading && true, modal: this.loading_modal_props,
      limit: query.limit, skip: query.skip
    });


    let currentLocation;
    try {
      let locationPromise = new Promise((resolve, reject) => {
        Geolocation.getCurrentPosition(
          position => {
            resolve(position);
          },
          error => {
            reject(error);
          },
          { enableHighAccuracy: false, timeout: 1000, maximumAge: 60000 }
        );
      });
      
      let timeoutPromise = new Promise((resolve, reject) => {
        let wait = setTimeout(() => {
          clearTimeout(wait);
          reject('Timeout');
        }, 5000)  // Timeout time
      });
      
      currentLocation = await Promise.race([locationPromise, timeoutPromise]);

      this.setState({
        location: currentLocation,
        locationError: null
      })

      if (currentLocation?.coords?.latitude && currentLocation?.coords?.longitude) {
        query.currentLatitude = currentLocation.coords.latitude;
        query.currentLongitude = currentLocation.coords.longitude;
      }
    } catch (error) {
      this.setState({
        location: null,
        locationError: error
      })
    }

    let barsRes = await barsService.get_bars(query);

    if(barsRes.error) {
      this.setState({
        loading: false,
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('error'),
          text: barsRes?.error_code ? convertMessageCodeToMessage(barsRes.error_code) : barsRes.error
        }
      });
      return
    }

    let bars = barsRes.bars;
    let totalBarsCount = barsRes.count ? barsRes.count : 0;
    if (options.push) {
      bars = [...this.state.bars, ...bars];
    }

    this.setState({
      bars: bars,
      totalBarsCount: totalBarsCount,
      isModalVisible: options.show_loading && false,
      loading: false
    });

  }

  render() {

    if (!this.state?.bars?.length) {
      return (
        <SafeAreaView style={styles.container}>
          <View style={commonStyles.centeredView}>
          
          {/* Location unavailable info text */}
          <LocationCheck locationError={this.state.locationError} />

          <ScrollView
            style={styles.scrollView}
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
            refreshControl={
              <RefreshControl
                refreshing={this.state.loading}
                onRefresh={async () => {
                  let query = {
                    // skiStation: this.ski_station_id
                  };
                  // if (this.props?.skiStation) {
                  //   query.skiStation = this.props.skiStation;
                  // }
                  await this.getBars(query, { show_loading: true });
                }}
              />
            }
          >

            <View style={{ alignItems: 'center' }}>
              {(Platform.OS == "web") ? (
                <Image source={require('./../assets/icons/address_home.svg')} style={{ width: 100, height: 100 }} />
              ) : (
                <BarAddressIcon width={100} height={100} />
              )}
              <Text style={{fontSize: 30, fontWeight: '600', marginTop: 30, color: config.primaryColor}}>
                {I18n.t('no_bars_found')}
              </Text>
                
              {(Platform.OS == 'web') ? (
                <RefreshButton
                  onPress={async () => {
                    let query = {
                      // skiStation: this.ski_station_id
                    };
                    // if (this.props?.skiStation) {
                    //   query.skiStation = this.props.skiStation;
                    // }
                    await this.getBars(query, { show_loading: true });
                  }}
                  refreshing={this.state.loading}
                />
              ) : null}

            </View>
            <AlertPopUpModal
              isVisible={this.state.isModalVisible}
              icon={this.state.modal.icon}
              title={this.state.modal.title}
              text={this.state.modal.text}
              okCallback={this.state.modal.okCallback}
            ></AlertPopUpModal>
          </ScrollView>

          <ScanTableQrCode
            {...this.props}
            text={I18n.t('scan_and_order')}
          />

          <View>
            <BottomNavigationBar {...this.props} activeTab={this.activeTab ? this.activeTab : "Bars"} />
          </View>
          </View>
        </SafeAreaView>
      );
    }

    return (
      <SafeAreaView style={styles.container}>
        <View style={commonStyles.centeredView}>
        
        <LocationCheck />
        
        <ScrollView style={styles.scrollView}
          refreshControl={
            <RefreshControl
              refreshing={this.state.loading}
              onRefresh={async () => {
                let query = {
                  // skiStation: this.ski_station_id
                };
                // if (this.props?.skiStation) {
                //   query.skiStation = this.props.skiStation;
                // }
                await this.getBars(query, { show_loading: true });
              }}
            />
          }
          paginationEnabled={true}
          onEndReachedThreshold={0.5}
          onMomentumScrollEnd={(event) => {
            let delta = (parseInt(event.nativeEvent.contentOffset.y) + parseInt(event.nativeEvent.layoutMeasurement.height)) - parseInt(event.nativeEvent.contentSize.height)
            // const isAtAnd = (parseInt(event.nativeEvent.contentOffset.y) + parseInt(event.nativeEvent.layoutMeasurement.height)) === parseInt(event.nativeEvent.contentSize.height);
            const isAtAnd = (delta > -10 && delta < 10) ? true : false;
            if (isAtAnd) {
              let query = {
                // skiStation: this.ski_station_id
              };
              // if (this.props?.skiStation) {
              //   query.skiStation = this.props.skiStation;
              // }
              this.getBars({ ...query, limit: this.state.limit, skip: this.state.skip + this.state.limit }, { show_loading: false, show_small_loading: true, push: true });
            }
          }}
          onScrollEndDrag={(event) => {
            let delta = (parseInt(event.nativeEvent.contentOffset.y) + parseInt(event.nativeEvent.layoutMeasurement.height)) - parseInt(event.nativeEvent.contentSize.height)
            // const isAtAnd = (parseInt(event.nativeEvent.contentOffset.y) + parseInt(event.nativeEvent.layoutMeasurement.height)) === parseInt(event.nativeEvent.contentSize.height);
            const isAtAnd = (delta > -10 && delta < 10) ? true : false;
            if (isAtAnd) {
              let query = {
                // skiStation: this.ski_station_id
              };
              // if (this.props?.skiStation) {
              //   query.skiStation = this.props.skiStation;
              // }
              this.getBars({ ...query, limit: this.state.limit, skip: this.state.skip + this.state.limit }, { show_loading: false, show_small_loading: true, push: true });
            }
          }}
        >
          {(Platform.OS == 'web') ? (
            <RefreshButton
              onPress={async () => {
                let query = {
                  // skiStation: this.ski_station_id
                };
                // if (this.props?.skiStation) {
                //   query.skiStation = this.props.skiStation;
                // }
                await this.getBars(query, { show_loading: true });
              }}
              refreshing={this.state.loading}
            />
          ) : null}

          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <View style={{ width: '100%' }}>
              
              {this.state.bars.map((bar, index) => {
                return (
                  <ItemHorizontal
                    key={index}
                    default_image={"bar"}
                    image_link={bar?.assets?.[0] ? generateAssetLink(bar.assets[0]) : null}
                    image_height={200}
                    title={bar.name}
                    secondary_title={bar?.description}
                    address={bar?.address}
                    location={bar?.location}
                    distance={bar?.distance}
                    onClick={() => this.props.navigation.navigate('Consumptions', { barId: bar._id })}
                    isOpen={bar?.isCurrentlyOpen}
                    disabled={bar?.isCurrentlyOpen ? false : true}
                    isHiddenBar={bar?.hidden}
                    isCancellable={bar?.hidden}
                    onCancel={async () => {
                      await barsService.remove_hidden_bar_locally(bar._id);
                      let query = {
                        // skiStation: this.ski_station_id
                      };
                      // if (this.props?.skiStation) {
                      //   query.skiStation = this.props.skiStation;
                      // }
                      await this.getBars(query, { show_loading: true });
                    }}
                  />
                )
              })}
              
            </View>
          </View>

          {(this.state.loading) ? (
            <View style={{alignItems: 'center'}}>
              <Image
                source={require('./../assets/images/loading.gif')}
                style={{height: 75, width: 100}}
              />
            </View>
          ) : null}

          {(Platform.OS == 'web' && this.state.totalBarsCount && (this.state.bars.length < this.state.totalBarsCount)) ? (
            <LoadMoreButton
              onPress={() => {
                let query = {
                  // skiStation: this.ski_station_id
                };
                // if (this.props?.skiStation) {
                //   query.skiStation = this.props.skiStation;
                // }
                this.getBars({ ...query, limit: this.state.limit, skip: this.state.skip + this.state.limit }, { show_loading: false, show_small_loading: true, push: true });
              }}
              refreshing={this.state.loading}
            />
          ) : null}

          <AlertPopUpModal
            isVisible={this.state.isModalVisible}
            icon={this.state.modal.icon}
            title={this.state.modal.title}
            text={this.state.modal.text}
            okCallback={this.state.modal.okCallback}
          ></AlertPopUpModal>

        </ScrollView>

        <ScanTableQrCode
          {...this.props}
          text={I18n.t('scan_and_order')}
        />

        <View>
          <BottomNavigationBar {...this.props} activeTab={this.activeTab ? this.activeTab : "Bars"} />
        </View>
        </View>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  scrollView: {
    backgroundColor: config.backgroundColor,
    marginHorizontal: 0,
  },
  text: {
    fontSize: 42,
  },
});

// Wrap and export
export default function(props) {
  const isFocused = useIsFocused();

  return <Bars {...props} isFocused={isFocused} />;
}

// export default Bars;
