import React from 'react';
import {View, Text, SafeAreaView, StyleSheet, ScrollView, TextInput, TouchableOpacity, KeyboardAvoidingView, TouchableWithoutFeedback, Keyboard, Platform} from 'react-native';
import config from '../config';
import authService from '../services/auth';
import I18n from "../utils/i18n";
import AlertPopUpModal from '../components/AlertPopUpModal';
import AsyncStorage from '@react-native-async-storage/async-storage';
import commonStyles from './../styles/common';

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.redirectTo =  this.props?.route?.params?.redirectTo || this.props.redirectTo;
    this.barId = this.props?.route?.params?.barId || this.props.barId;
    this.tableNumber = this.props?.route?.params?.tableNumber || this.props.tableNumber;

    this.default_modal_props = {
      icon: '',
      title: '',
      text: '',
    }

    this.loading_modal_props = {
      ...this.default_modal_props,
      icon: 'loading',
      title: I18n.t('loading')
    }

    this.state = {
      full_name: '',
      email: '',
      username: '',
      password: '',
      confirm_password: '',
      loading: false,
      isModalVisible: false,
      modal: {
        icon: '',
        title: '',
        text: '',
        okCallback: null
      }
    };
  }

  async register() {

    if (!this.validateForm()) {
      return
    }

    this.setState({
      loading: true,
      isModalVisible: true,
      modal: {
        ...this.loading_modal_props
      }
    });

    let payload = {
      fullname: this.state.full_name,
      username: this.state.username,
      email: this.state.email,
      password: this.state.password
    }
    let registerRes = await authService.register(payload);
    if (registerRes.error) {
      this.setState({
        loading: true,
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t(registerRes.error_code),
          okCallback: () => {
            this.setState({
              loading: false,
              isModalVisible: false
            });
          }
        }
      });
      return
    }

    this.setState({
      loading: true,
      isModalVisible: true,
      modal: {
        ...this.default_modal_props,
        icon: 'success',
        title: I18n.t(registerRes.message_code),
        okCallback: async () => {
          try {
            await AsyncStorage.setItem(config.tokenKey, registerRes.token);
            await AsyncStorage.setItem(config.roleKey, registerRes.user.role);
            this.props.navigation.reset({
              index: 0,
              routes: [{ name: this.redirectTo ? this.redirectTo : 'Home', params: { barId: this.barId, tableNumber: this.tableNumber } }],
            });
            this.setState({
              loading: false,
              isModalVisible: false
            });
          } catch (e) {
            this.setState({
              loading: true,
              isModalVisible: true,
              modal: {
                ...this.default_modal_props,
                icon: 'error',
                title: I18n.t('unknown_error_occurred'),
                text: I18n.t('please_login_again'),
                okCallback: () => {
                  this.props.navigation.reset({
                    index: 0,
                    routes: [{ name: 'Login', params: { redirectTo: this.redirectTo, barId: this.barId, tableNumber: this.tableNumber } }]
                  });
                  this.setState({
                    loading: false,
                    isModalVisible: false
                  });
                }
              }
            })
          }
        }
      }
    });
  }

  validateEmail() {
    if (this.state.email === '') {
      return true;
    }
    var re = /\S+@\S+\.\S+/;
    return re.test(this.state.email);
  }

  validatePasswords() {
    return this.state.password === this.state.confirm_password;
  }

  validateForm() {
    if (
      this.state.full_name === '' ||
      this.state.email === '' ||
      this.state.username === '' ||
      this.state.password === '' ||
      this.state.confirm_password === ''
    ) {
      return false;
    }

    if (
      this.state.password.length < 6
    ) {
      return false;
    }

    // validate passwords
    if (!this.validatePasswords()) {
      return false;
    }

    // validate email with regex
    if (!this.validateEmail()) {
      return false;
    }


    return true;
  }


  render() {
    return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
      >
        <SafeAreaView style={styles.container}>
          <View style={commonStyles.centeredView}>
          <ScrollView style={styles.scrollView} contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}>
            
              <Text style={styles.title}>{I18n.t('create_an_account')}</Text>

              <Text style={styles.inputHeader}>{I18n.t('full_name')}</Text>
              <TextInput
                ref={(input) => { this.fullNameInput = input; }}
                style={styles.input}
                placeholder={I18n.t('full_name')}
                placeholderTextColor={'gray'}
                onChangeText={(e) => {
                  this.setState({ full_name: e });
                }}
                value={this.state.full_name}
                autoCapitalize='none'
                returnKeyType="next"
                onSubmitEditing={() => { this.emailInput.focus(); }}
              />
              
              <Text style={styles.inputHeader}>{I18n.t('email')}</Text>
              <TextInput
                ref={(input) => { this.emailInput = input; }}
                style={styles.input}
                placeholder={I18n.t('email')}
                placeholderTextColor={'gray'}
                onChangeText={(e) => {
                  this.setState({ email: e });
                }}
                value={this.state.email}
                autoCapitalize='none'
                returnKeyType="next"
                onSubmitEditing={() => { this.usernameInput.focus(); }}
              />
              {this.validateEmail() ? null : <Text style={styles.inputHelper}>{I18n.t('invalid_email')}</Text>}
              
              <Text style={styles.inputHeader}>{I18n.t('username')}</Text>
              <TextInput
                ref={(input) => { this.usernameInput = input; }}
                style={styles.input}
                placeholder={I18n.t('username')}
                placeholderTextColor={'gray'}
                onChangeText={(e) => {
                  this.setState({ username: e });
                }}
                value={this.state.username}
                autoCapitalize='none'
                returnKeyType="next"
                onSubmitEditing={() => { this.passwordInput.focus(); }}
              />
              
              <Text style={styles.inputHeader}>{I18n.t('password')}</Text>
              <TextInput
                ref={(input) => { this.passwordInput = input; }}
                style={styles.input}
                placeholder={I18n.t('password')}
                placeholderTextColor={'gray'}
                secureTextEntry={true}
                onChangeText={(e) => {
                  this.setState({ password: e });
                }}
                value={this.state.password}
                autoCapitalize='none'
                returnKeyType="next"
                onSubmitEditing={() => { this.confirmPasswordInput.focus(); }}
              />
              {(this.state.password.length && this.state.password.length < 6) ? (
                <Text style={styles.inputHelper}>{I18n.t('password_should_be_atleast_6_characters')}</Text>
              ) : ( null )}
              
              <Text style={styles.inputHeader}>{I18n.t('confirm_password')}</Text>
              <TextInput
                ref={(input) => { this.confirmPasswordInput = input; }}
                style={styles.input}
                placeholder={I18n.t('confirm_password')}
                placeholderTextColor={'gray'}
                secureTextEntry={true}
                onChangeText={(e) => {
                  this.setState({ confirm_password: e });
                }}
                value={this.state.confirm_password}
                autoCapitalize='none'
                onSubmitEditing={() => {
                  this.register();
                }}
              />
              {this.validatePasswords() ? null : (
                <Text style={styles.inputHelper}>{I18n.t('passwords_didnt_match')}</Text>
              )}

              <TouchableOpacity
                style={(!this.validateForm() || this.state.loading) ? styles.disabled_button : styles.button}
                onPress={() => {
                  this.register();
                }}
                disabled = {(!this.validateForm() || this.state.loading)}
              >
                <Text style={styles.buttonText}>{this.state.loading ? I18n.t('loading') : I18n.t('register')}</Text>
              </TouchableOpacity>

              <Text style={{textAlign: 'center', margin: 20, color: 'black'}}>
                {I18n.t('already_have_an_account')} <Text style={{ color: config.primaryColor }} onPress={async () => {
                  this.setState({
                    loading: true,
                    isModalVisible: true,
                    modal: this.loading_modal_props
                  });
                  this.props.navigation.reset({
                    index: 0,
                    routes: [{ name: 'Login', params: { redirectTo: this.redirectTo, barId: this.barId, tableNumber: this.tableNumber } }],
                  });
                  this.setState({
                    loading: false,
                    isModalVisible: false
                  });
                }}>{I18n.t('login')}</Text>
              </Text>

              <AlertPopUpModal
                isVisible={this.state.isModalVisible}
                icon={this.state.modal.icon}
                title={this.state.modal.title}
                text={this.state.modal.text}
                okCallback={this.state.modal.okCallback}
              ></AlertPopUpModal>

          </ScrollView>
          </View>
        </SafeAreaView>
      </KeyboardAvoidingView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  title: {
    padding: 15,
    margin: 20,
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
    color: config.primaryColor
  },
  inputHeader: {
    // margin: 10,
    padding: 15,
    paddingBottom: 10,
    fontSize: 15,
    color: 'black'
  },
  input: {
    // borderWidth: 0,
    // borderColor: config.primaryColor,
    borderRadius: 50,
    height: 50,
    backgroundColor: '#dfdfdf',
    margin: 10,
    marginTop: 0,
    padding: 15,
    color: 'black'
  },
  inputHelper: {
    margin: -10,
    padding: 30,
    paddingTop: 0,
    paddingBottom: 10,
    fontSize: 12,
    color: 'red'
  },
  scrollView: {
    backgroundColor: 'white',
    marginHorizontal: 0
  },
  text: {
    fontSize: 42,
  },
  button: {
    backgroundColor: config.primaryColor,
    padding: 15,
    margin: 10,
    marginTop: 20,
    borderRadius: 50,
    alignItems: 'center',
  },
  disabled_button: {
    backgroundColor: 'gray',
    padding: 15,
    margin: 10,
    marginTop: 20,
    borderRadius: 50,
    alignItems: 'center',
  },
  buttonText: {
    color: 'white',
    fontSize: 20,
  }
});

export default Register;
