import AsyncStorage from '@react-native-async-storage/async-storage';
import React from 'react';
import {Text, SafeAreaView, StyleSheet, ScrollView, TextInput, TouchableOpacity, KeyboardAvoidingView, Platform, Linking, View, Image} from 'react-native';
import AlertPopUpModal from '../components/AlertPopUpModal';
import config from '../config';
import authService from '../services/auth';
import I18n from "../utils/i18n";
import commonStyles from './../styles/common';
import LeftChevronIcon from './../assets/icons/left-chevron.svg';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.redirectTo =  this.props?.route?.params?.redirectTo || this.props.redirectTo;
    this.barId = this.props?.route?.params?.barId || this.props.barId;
    this.tableNumber = this.props?.route?.params?.tableNumber || this.props.tableNumber;

    this.default_modal_props = {
      icon: '',
      title: '',
      text: '',
      okCallback: null
    }

    this.loading_modal_props = {
      ...this.default_modal_props,
      icon: 'loading',
      title: I18n.t('loading')
    }

    this.state = {
      username: '',
      password: '',
      fullname: '',
      loading: false,
      isModalVisible: false,
      appVersionError: false,
      modal: {
        icon: '',
        title: '',
        text: '',
      }
    };
  }

  async tryAutoLogin() {
    this.setState({
      isModalVisible: true,
      modal: {
        ...this.loading_modal_props
      }
    });
    let verified = await authService.verifyToken();
    if (verified.error) {
      // try {
      //   // await AsyncStorage.multiRemove([config.tokenKey, config.roleKey, config.roleValueKey]);
      // } catch (e) {
      //   // clear error
      // }
      this.setState({
        isModalVisible: false
      });
    } else {
      await AsyncStorage.setItem(config.tokenKey, verified.token);
      await AsyncStorage.setItem(config.roleKey, verified.role);
      await AsyncStorage.setItem(config.roleValueKey, String(verified.value));
      await AsyncStorage.setItem(config.barsKey, JSON.stringify(verified.bars));
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: this.redirectTo ? this.redirectTo : 'Home', params: { barId: this.barId, tableNumber: this.tableNumber } }]
      });
      this.setState({
        isModalVisible: false
      });
    }
  }


  async componentDidMount() {

    // show loading
    await this.setState({
      isModalVisible: true,
      modal: {
        ...this.loading_modal_props
      }
    });

    // Check App Version
    let current_app_version = config.CURRENT_APP_VERSION;
    let app_version = await authService.getAppVersion();
    let required_app_version = app_version.requiredAppVersion;
    let warning_app_version = app_version.warningAppVersion;

    if (current_app_version < required_app_version) {
      await this.setState({
        appVersionError: true,
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('this_app_version_is_not_supported') + ' - ' + current_app_version,
          text: I18n.t('please_update_your_app'),
          okCallback: null
        }
      });
      return;
    }

    if (current_app_version < warning_app_version) {
      await this.setState({
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'warning',
          title: I18n.t('please_update_your_app'),
          okCallback: async () => {
            await this.setState({
              isModalVisible: false
            });
            this.tryAutoLogin();
          }
        }
      });
    } else {
      this.tryAutoLogin();
    }
  }

  async login() {

    if (this.state.username === '' || this.state.password === '') {
      return;
    }

    this.setState({ loading: true });
    let loginRes = await authService.login(this.state.username, this.state.password);
    if (loginRes.error) {
      await this.setState({
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t(loginRes.error_code),
          okCallback: () => {
            this.setState({
              loading: false,
              isModalVisible: false
            });
          }
        }
      });
      return
    }
    await AsyncStorage.setItem(config.tokenKey, loginRes.token);
    await AsyncStorage.setItem(config.roleKey, loginRes.role);
    await AsyncStorage.setItem(config.roleValueKey, String(loginRes.value));
    await AsyncStorage.setItem(config.barsKey, JSON.stringify(loginRes.bars));
    this.setState({ loading: false });
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: this.redirectTo ? this.redirectTo : 'Home', params: { barId: this.barId, tableNumber: this.tableNumber } }],
    });
  }

  async continueWithouAnAccount() {

    if (this.state.fullname === '') {
      return;
    }

    this.setState({ loading: true });
    // Create a guest user and login automatically
    let guestUserRes = await authService.loginAsGuest({
      fullname: this.state.fullname
    });
    if (guestUserRes.error) {
      this.setState({
        isModalVisible: true,
        modal: {
          icon: 'error',
          title: I18n.t('error'),
          text: guestUserRes.error_code ? I18n.t(guestUserRes.error_code) : guestUserRes.error,
          okCallback: () => {
            this.setState({
              loading: false,
              isModalVisible: false
            });
          }
        }
      });
      return;
    }

    await AsyncStorage.setItem(config.tokenKey, guestUserRes.token);
    await AsyncStorage.setItem(config.roleKey, guestUserRes.role);
    await AsyncStorage.setItem(config.roleValueKey, String(guestUserRes.value));
    await AsyncStorage.setItem(config.barsKey, JSON.stringify(guestUserRes.bars));
    this.setState({ loading: false });
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: this.redirectTo ? this.redirectTo : 'Home', params: { barId: this.barId, tableNumber: this.tableNumber } }],
    });
  }

  render() {
    return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
      >
        <SafeAreaView style={styles.container}>
        <View style={commonStyles.centeredView}>
          <ScrollView
            style={styles.scrollView}
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
            keyboardShouldPersistTaps={'handled'}
          >

            {(this.state.appVersionError) ? (
              <>
                <Text style={styles.title}>{I18n.t('this_app_version_is_not_supported') + ' - ' + config.CURRENT_APP_VERSION}</Text>
                <Text style={styles.title}>{I18n.t('please_update_your_app')}</Text>
              </>
            ) : (
              <>

                <View>
                  <TouchableOpacity onPress={() => {
                    // go back, if unable to go back, go to home
                    if (this.props.navigation.canGoBack()) {
                      this.props.navigation.goBack();
                    } else {
                      this.props.navigation.reset({
                        index: 0,
                        routes: [{ name: 'Home' }]
                      });
                    }
                  }}>
                    {(Platform.OS === 'web') ? (
                      <Image
                        source={require('./../assets/icons/left-chevron.svg')} style={{width: 40, height: 40}}
                      />
                    ) : (
                      <LeftChevronIcon width={40} height={40} />
                    )}
                  </TouchableOpacity>
                </View>

                <Text style={styles.title}>{I18n.t('welcome_back')}</Text>

                <Text style={styles.inputHeader}>{I18n.t('username')}</Text>
                <TextInput
                  style={styles.input}
                  placeholder={I18n.t('username')}
                  placeholderTextColor={'gray'}
                  onChangeText={(e) => {
                    this.setState({ username: e });
                  }}
                  value={this.state.username}
                  autoCapitalize='none'
                  returnKeyType="next"
                  onSubmitEditing={() => { this.passwordInput.focus(); }}
                />

                <Text style={styles.inputHeader}>{I18n.t('password')}</Text>
                <TextInput
                  ref={(input) => { this.passwordInput = input; }}
                  style={styles.input}
                  placeholder={I18n.t('password')}
                  placeholderTextColor={'gray'}
                  secureTextEntry={true}
                  onChangeText={(e) => {
                    this.setState({ password: e });
                  }}
                  value={this.state.password}
                  autoCapitalize='none'
                  onSubmitEditing={() => {
                    this.login();
                  }}
                />
                
                {/* <Text style={{textAlign: 'right', color: 'black', paddingRight: 15, color: config.primaryColor }} onPress={async () => {
                  await this.setState({
                    isModalVisible: true,
                    modal: {
                      ...this.default_modal_props,
                      icon: 'info',
                      text: I18n.t('SENTENCE.forgot_password_text'),
                      okText: I18n.t('send_mail'),
                      okCallback: () => {
                        Linking.openURL('mailto:info@jm-contactless.ch')
                        this.setState({
                          isModalVisible: false
                        });
                      },
                      cancelCallback: () => {
                        this.setState({
                          isModalVisible: false
                        });
                      }
                    }
                  });
                }}>{I18n.t('i_forgot_my_password')}</Text> */}

                <TouchableOpacity
                  style={ (this.state.username.length && this.state.password.length && !this.state.loading) ? styles.button : styles.disabled_button}
                  onPress={() => {
                    this.login();
                  }}
                  disabled = {this.state.username.length === 0 || this.state.password.length === 0 || this.state.loading}
                >
                  <Text style={styles.buttonText}>{this.state.loading ? I18n.t('loading') : I18n.t('login')}</Text>
                </TouchableOpacity>

                {/* Divider line with a text in the middle */}
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                  <View style={{ flex: 1, height: 1, backgroundColor: 'black' }} />
                  <View>
                    <Text style={{ width: '100%', textAlign: 'center', paddingHorizontal: 20, color: 'black', fontSize: 20, fontWeight: '600' }}>{I18n.t('continue_without_an_account')}</Text>
                  </View>
                  <View style={{ flex: 1, height: 1, backgroundColor: 'black' }} />
                </View>


                <Text style={styles.inputHeader}>{I18n.t('full_name')}</Text>
                <TextInput
                  style={styles.input}
                  placeholder={I18n.t('full_name')}
                  placeholderTextColor={'gray'}
                  onChangeText={(e) => {
                    this.setState({ fullname: e });
                  }}
                  value={this.state.fullname}
                  autoCapitalize='none'
                  returnKeyType="next"
                  onSubmitEditing={() => { this.passwordInput.focus(); }}
                />

                <TouchableOpacity
                  style={ (this.state.fullname.length && !this.state.loading) ? styles.button : styles.disabled_button}
                  onPress={() => {
                    this.continueWithouAnAccount();
                  }}
                  disabled = {this.state.fullname.length === 0 || this.state.loading}
                >
                  <Text style={styles.buttonText}>{this.state.loading ? I18n.t('loading') : I18n.t('continue_without_an_account')}</Text>
                </TouchableOpacity>

                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                  <View style={{ flex: 1, height: 1, backgroundColor: 'black' }} />
                  <View>
                    <Text style={{ width: '100%', textAlign: 'center', paddingHorizontal: 20, color: 'black', fontSize: 20, fontWeight: '600' }}>{I18n.t('create_an_account')}</Text>
                  </View>
                  <View style={{ flex: 1, height: 1, backgroundColor: 'black' }} />
                </View>

                <TouchableOpacity
                  style={styles.button}
                  onPress={() => {
                    this.props.navigation.reset({
                      index: 0,
                      routes: [{ name: 'RegisterWithPhoneNumber', params: { redirectTo: this.redirectTo, barId: this.barId, tableNumber: this.tableNumber } }]
                    });
                  }}
                >
                  <Text style={styles.buttonText}>{I18n.t('continue_with_phone_number')}</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.button}
                  onPress={() => {
                    this.props.navigation.reset({
                      index: 0,
                      routes: [{ name: 'RegisterWithEmail', params: { redirectTo: this.redirectTo, barId: this.barId, tableNumber: this.tableNumber } }]
                    });
                  }}
                >
                  <Text style={styles.buttonText}>{I18n.t('continue_with_email')}</Text>
                </TouchableOpacity>
              
              </>
            )}

            <AlertPopUpModal
              isVisible={this.state.isModalVisible}
              icon={this.state.modal.icon}
              title={this.state.modal.title}
              text={this.state.modal.text}
              okText={this.state.modal.okText}
              okCallback={this.state.modal.okCallback}
              cancelCallback={this.state.modal.cancelCallback}
            ></AlertPopUpModal>

          </ScrollView>
        </View>
        </SafeAreaView>
      </KeyboardAvoidingView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  title: {
    padding: 15,
    margin: 20,
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
    color: config.primaryColor
  },
  inputHeader: {
    // margin: 10,
    padding: 15,
    paddingBottom: 10,
    fontSize: 15,
    color: 'black'
  },
  input: {
    // borderWidth: 0,
    // borderColor: config.primaryColor,
    borderRadius: 5,
    height: 50,
    backgroundColor: 'white',
    borderColor: 'gray',
    borderWidth: 1,
    margin: 10,
    marginTop: 0,
    padding: 15,
    color: 'black'
  },
  scrollView: {
    backgroundColor: 'white',
    marginHorizontal: 0
  },
  text: {
    fontSize: 42,
  },
  button: {
    backgroundColor: config.primaryColor,
    padding: 15,
    margin: 10,
    marginTop: 20,
    borderRadius: 5,
    alignItems: 'center',
  },
  disabled_button: {
    backgroundColor: 'gray',
    padding: 15,
    margin: 10,
    marginTop: 20,
    borderRadius: 5,
    alignItems: 'center',
  },
  buttonText: {
    color: 'white',
    fontSize: 20,
  }
});

export default Login;
