import { Platform } from "react-native"

let centeredView = {}
let leftSideView = {}
let rightSideView = {}

if (Platform.OS == 'web') {
    centeredView = {
        width: '100%',
        maxWidth: '650px',
        height: '100%',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
} else {
    centeredView = {
        width: '100%',
        height: '100%',
    }
}

leftSideView = {
    width: '45%',
    height: '100%'
}

rightSideView = {
    width: '55%',
    height: '100%'
}

const commonStyles = {
    centeredView,
    leftSideView,
    rightSideView
}

export default commonStyles;