import React from 'react';
import I18n from '../utils/i18n';
import { View, Text, Image, Platform, TouchableOpacity, Linking } from 'react-native';
import LocationErrorIcon from '../assets/icons/location_error.svg';
import Geolocation from '@react-native-community/geolocation';

// const LOCATION_ERROR_CODES = {
//     '1': 'PERMISSION_DENIED',
//     '2': 'POSITION_UNAVAILABLE',
//     '3': 'TIMEOUT',
//     '4': 'ACTIVITY_NULL',
//     '5': 'UNKNOWN_ERROR'
// }

class LocationCheck extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            location: null,
            locationError: null,
            locationErrorCode: null
        }
    }

    async componentDidMount() {
        try {
            let currentLocation = await new Promise((resolve, reject) => {
                Geolocation.getCurrentPosition(
                    position => {
                        resolve(position);
                    },
                    error => {
                        this.setState({
                            location: null,
                            locationError: error,
                            locationErrorCode: error?.code ? String(error.code) : '5'
                        });
                        reject(error);
                    },
                    { enableHighAccuracy: false, timeout: 1000, maximumAge: 1000 }
                );
            });
            this.setState({
                location: currentLocation,
                locationError: null
            })
        } catch (error) {
            this.setState({
                location: null,
                locationError: error,
                locationErrorCode: error?.code ? String(error.code) : '5'
            });
        }
    }

    render() {

        if(!this.state.locationError) {
            return null;
        }

        return (
            <View style={{ alignItems: 'center', paddingTop: 5, paddingBottom: 5, borderBottomColor: 'red', borderBottomWidth: 2, justifyContent: 'space-around', flexDirection: 'row'}}>
                {/* Location error icon */}
                <View style={{alignItems: 'center', paddingHorizontal: 30}}>
                    {(Platform.OS == "web") ? (
                        <Image source={require('./../assets/icons/location_error.svg')} style={{ width: 40, height: 40 }} />
                    ) : (
                        <LocationErrorIcon width={40} height={40} />
                    )}
                </View>
                {/* Location error text */}
                <View style={{alignItems: 'center', paddingHorizontal: 30}}>
                    {(this.state.locationErrorCode == '1') ? (
                        <Text style={{ fontSize: 16, fontWeight: '400', color: 'black', textAlign: 'center' }}>{I18n.t('LOCATION_ERROR.PERMISSION_DENIED_INFO')}</Text>
                    ) : (
                        <Text style={{ fontSize: 16, fontWeight: '400', color: 'black', textAlign: 'center' }}>{I18n.t('LOCATION_ERROR.POSITION_UNAVAILABLE')}</Text>
                    )}
                </View>
            </View>
        );
    }

}

export default LocationCheck;