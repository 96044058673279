import React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import config from '../config';
import I18n from '../utils/i18n';

class RefreshButton extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(nextProps, nextState) {
    nextState = {
        ...nextState,
        ...nextProps
    }
  }

  render() {

    return (
        <TouchableOpacity
            style={this.props?.refreshing ? {padding: 10, backgroundColor: 'gray', margin: 10, borderRadius: 10, alignItems: 'center'} : {padding: 10, backgroundColor: config.primaryColor, margin: 10, borderRadius: 10, alignItems: 'center'}}
            disabled={this.props?.refreshing}
            onPress={() => {
                this.props?.onPress();
            }}
        >
            <Text style={{color: 'white', fontSize: 15, fontWeight: '700'}}>{this.props?.refreshing ? I18n.t('refreshing') : I18n.t('refresh')}</Text>
        </TouchableOpacity>
    );
  }
}
export default RefreshButton;