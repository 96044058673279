import React from "react";

class Payment extends React.Component {

    constructor(props) {
        super(props);
        this.payrexxLink = this.props?.route?.params?.payrexxLink;
        this.orderGroupId = this.props?.route?.params?.orderGroupId;
        this.payrexxId = this.props?.route?.params?.payrexxId;
        this.payrexxReferenceId = this.props?.route?.params?.payrexxReferenceId;
        this.redirectTo = this.props?.route?.params?.redirectTo ? this.props?.route?.params?.redirectTo : 'Orders';

        window.addEventListener('message', this.handleMessage, false);
    }

    handleMessage = (e) => {
        if (typeof e.data === 'string') {
          try {
            var data = JSON.parse(e.data);
          } catch (e) {}
          
          if (data?.payrexx?.transaction?.status) {
            this.props.navigation.reset({
              index: 0,
              routes: [{ name: this.redirectTo, params: { orderId: this.orderId, orderGroupId: this.orderGroupId, payrexxId: this.payrexxId, payrexxReferenceId: this.payrexxReferenceId } }],
            });
            return
          }
        }
    }

    render() {
        if (!this.payrexxLink) {
            // goback
            this.props.navigation.goBack();
            return (
                <></>
            )
        }
        return (
            <iframe id="payrexx" src={this.payrexxLink} width="100%" height="100%"
              onLoad={() => {
                var iFrame = document.getElementById('payrexx');
                iFrame.contentWindow.postMessage(
                    JSON.stringify({
                        origin: window.location.origin
                    }),
                    iFrame.src
                )
              }}
              onError={() => {
                this.props.navigation.reset({
                  index: 0,
                  routes: [{ name: this.redirectTo, params: { orderId: this.orderId, orderGroupId: this.orderGroupId, payrexxId: this.payrexxId, payrexxReferenceId: this.payrexxReferenceId } }],
                });
                return
              }}
              loading="lazy"
            ></iframe>
        )
    }
}

export default Payment;
