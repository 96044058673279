import React from 'react';
import OrderGroups from './OrderGroups';

class OrderGroupHistory extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <OrderGroups
        {...this.props}
        history={true}
      />
    );
  }
}

export default OrderGroupHistory;
