import React from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import config from "../config";
import I18n from "../utils/i18n";

class CountryList extends React.Component {

    constructor(props) {
        super(props);

        this.selectedCountry = this.props?.selectedCountry ? this.props.selectedCountry : 'CH';

        this.state = {
            countries: [
                {
                    name: I18n.t('country_list.switzerland'),
                    code: 'CH',
                    tel: '41',
                    flag: '🇨🇭',
                    selected: this.selectedCountry === 'CH' ? true : false
                },
                {
                    name: I18n.t('country_list.germany'),
                    code: 'DE',
                    tel: '49',
                    flag: '🇩🇪',
                    selected: this.selectedCountry === 'DE' ? true : false
                },
                {
                    name: I18n.t('country_list.france'),
                    code: 'FR',
                    tel: '33',
                    flag: '🇫🇷',
                    selected: this.selectedCountry === 'FR' ? true : false
                },
                {
                    name: I18n.t('country_list.italy'),
                    code: 'IT',
                    tel: '39',
                    flag: '🇮🇹',
                    selected: this.selectedCountry === 'IT' ? true : false
                },
                {
                    name: I18n.t('country_list.united_kingdom'),
                    code: 'GB',
                    tel: '44',
                    flag: '🇬🇧',
                    selected: this.selectedCountry === 'GB' ? true : false
                },
                {
                    name: I18n.t('country_list.united_states'),
                    code: 'US',
                    tel: '1',
                    flag: '🇺🇸',
                    selected: this.selectedCountry === 'US' ? true : false
                },
                {
                    name: I18n.t('country_list.india'),
                    code: 'IN',
                    tel: '91',
                    flag: '🇮🇳',
                    selected: this.selectedCountry === 'IN' ? true : false
                }
            ]
        }

        if(this.props.onCountryChange)
            this.props.onCountryChange(this.state.countries.filter((country) => country.selected)[0]);
    }

    render() {
        return (
            <View style={{margin: 10, height: '30%', maxHeight: 250}}>
                <ScrollView style={{borderColor: 'grey', borderWidth: 1, borderRadius: 10}}>
                    {this.state.countries.map((country) => (
                        <View key={country.code}>
                            <TouchableOpacity
                                onPress={() => {
                                    this.setState({
                                        countries: this.state.countries.map((c) => {
                                            if (c.code === country.code) {
                                                c.selected = true;
                                            } else {
                                                c.selected = false;
                                            }
                                            return c;
                                        })
                                    });
                                    if(this.props.onCountryChange)
                                        this.props.onCountryChange(country);
                                }}
                            >
                                <View style={country?.selected ? styles.selectedView : styles.view}>
                                    <Text style={country?.selected ? styles.selectedText : styles.text}>{country.flag}   {country.name}</Text>
                                    <Text style={country?.selected ? styles.selectedText : styles.text}>{'+ ' + country.tel}</Text>
                                </View>
                            </TouchableOpacity>
                            {/* Line */}
                            <View style={{borderBottomColor: 'grey', borderBottomWidth: 1}}></View>
                        </View>
                    ))}
                </ScrollView>
            </View>
        );
    }
}

const styles = {
    selectedView: {
        flexDirection: 'row', justifyContent: 'space-between', padding: 10, backgroundColor: config.primaryColor, margin: 5, borderRadius: 10
    },
    view: {
        flexDirection: 'row', justifyContent: 'space-between', padding: 10, backgroundColor: 'white', margin: 5, borderRadius: 10
    },
    selectedText: {
        fontSize: 20, color: 'white', fontWeight: 'bold'
    },
    text: {
        fontSize: 20, color: 'gray', fontWeight: 'bold'
    }
}

export default CountryList;