import React from "react";
import { Image, Platform, Text, TouchableOpacity, View } from "react-native";
import ScanQR from "./ScanQr";
import QRCodeIcon from './../assets/icons/qrcode.svg';
import config from "../config";
import i18n from "../utils/i18n";
import PopUpModal from "./PopUpModal";

let qr_code_icon;

if (Platform.OS == 'web') {
    qr_code_icon = <Image source={require('./../assets/icons/qrcode.svg')} style={{width: 50, height: 50}} />
} else {
    qr_code_icon = <QRCodeIcon width={50} height={50} />
}

class ScanTableQrCode extends React.Component {
    constructor(props) {
        super(props);

        this.text = this.props?.route?.params?.text || this.props?.text;      // 'consumptions', 'pay'

        this.state = {
            isModalVisible: false
        }

    }

    render() {
        return (
            <>
                <TouchableOpacity
                    style={{position: 'absolute', zIndex: 900, bottom: 90, backgroundColor: config.primaryColor, borderRadius: 10, flexDirection: 'row', alignItems: 'center', padding: 10, right: 20, marginLeft: 40}}
                    onPress={() => {
                        this.setState({
                            isModalVisible: true
                        })
                    }}
                >
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        {(this.text) ? (
                            <View style={{flexShrink: 1}}>
                                <Text
                                    style={{fontWeight: '700', color: 'white', marginRight: 10, fontSize: 20}}
                                >{this.text}</Text>
                            </View>
                        ) : null}
                        <View>
                            {qr_code_icon}
                        </View>
                    </View>
                </TouchableOpacity>

                {(true) ? (
                    <PopUpModal
                        isVisible={this.state.isModalVisible}
                        title={i18n.t('scan_qr_code')}
                    >
                        <Text
                            style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                textAlign: 'center',
                                marginBottom: 20,
                                color: 'black'
                            }}
                        >
                            {i18n.t('scan_qr_code')}
                        </Text>
                        <ScanQR
                            onReadCode={(code) => {
                                this.setState({
                                    isModalVisible: false
                                })
                                let qrCode = code;
                                let query_params = qrCode.split('?')[1];
                                let params = {};
                                query_params.split('&').forEach((param) => {
                                    let key_value = param.split('=');
                                    params[key_value[0]] = key_value[1];
                                });

                                if(this.props?.onReadCode) {
                                    this.props.onReadCode(params);
                                    return;
                                }

                                this.props.navigation.navigate('Consumptions', {
                                    barId: params.barId,
                                    tableNumber: params.tableNumber
                                });
                                
                            }}
                        />
                        {/* Cancel button */}
                        <TouchableOpacity
                            style={{
                                backgroundColor: config.primaryColor,
                                padding: 10,
                                borderRadius: 10,
                                marginTop: 20
                            }}
                            onPress={() => {
                                this.setState({
                                    isModalVisible: false
                                })
                            }}
                        >
                            <Text style={{color: 'white', fontSize: 20, textAlign: 'center'}}>{i18n.t('cancel')}</Text>
                        </TouchableOpacity>
                    </PopUpModal>
                ) : null}
            </>
        )
    }
}

export default ScanTableQrCode;