import AsyncStorage from "@react-native-async-storage/async-storage";
import { I18n } from "i18n-js";
import * as RNLocalize from "react-native-localize";

import en from "./locales/en";
import fr from "./locales/fr";
import de from "./locales/de";

const locales = RNLocalize.getLocales();

const i18n = new I18n({
    en: en,
    fr: fr,
    de: de,
});

i18n.fallbacks = true;
i18n.defaultLocale = "en";
i18n.locale = "en";

if (Array.isArray(locales)) {
    i18n.locale = locales[0].languageCode;
}

AsyncStorage.getItem("language").then((language) => {
    if (language) {
        i18n.locale = language;
    }
});

export default i18n;