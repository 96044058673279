"use strict";
exports.__esModule = true;
var ThermalPrinter = require(".").printer;
var PrinterTypes = require(".").types;
function setFontSize(nb, printer) {
    var width = [48, 24];
    if (nb < 0 || nb > width.length - 1) {
        throw "The size must be between 0 and 1";
    }
    printer.setTextSize(nb, nb);
    printer.config.width = width[nb];
}

function printReceiptWithNumber(eventName, ips, ticketNumber, articles, total) {
    ips.forEach(function (ip) {
        var printer = new ThermalPrinter({
            type: PrinterTypes.EPSON,
            interface: 'tcp://' + ip // Printer interface
        });
        printer.alignCenter();
        setFontSize(1, printer);
        var title = eventName.split('-');
        if(title.length == 2) {
            printer.println(title[0].trim());
            printer.println(title[1].trim());
        } else {
            printer.println(eventName);
        }
        printer.newLine();
        printer.alignCenter();
        printer.setTextSize(7, 7);
        printer.println(ticketNumber);
        setFontSize(0, printer);
        printer.newLine();
        for(let article of articles){
            printer.leftRight(article.nbr + "x " + article.name, (Math.round(article.totalPrice * 100) / 100).toFixed(2) + " CHF");
            printer.newLine();
        }
        printer.drawLine();
        printer.newLine();
        printer.bold(true);
        setFontSize(1, printer);
        printer.leftRight("", (Math.round(total * 100) / 100).toFixed(2) + " CHF");
        printer.bold(false);
        setFontSize(0, printer);
        var date = new Date();
        var dateNow = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + date.getFullYear();
        var hoursAndMinutes = ((date.getHours() < 10) ? ("0" + date.getHours()) : date.getHours()) + ':' + ((date.getMinutes() < 10) ? ("0" + date.getMinutes()) : date.getMinutes());
        printer.newLine();
        printer.alignRight();
        printer.println(dateNow + " " + hoursAndMinutes);
        printer.cut();
        try {
            printer.execute();
            printer.clear();
            console.log("Print done!");
        }
        catch (error) {
            console.error("Print failed:", error);
        }
    });
}

function printDailyCount(barInfos, amountsInfos, categoriesConsumption) {
    barInfos.printersIps.forEach(function (printerIp) {
        try {
            var printer = new ThermalPrinter({
                type: PrinterTypes.EPSON,
                interface: 'tcp://' + printerIp // Printer interface
            });
            var totalNetRevenues = amountsInfos.totalRevenues - amountsInfos.discountTTC;
            var totalTVA = 0;
            for(var categorieConsumption of categoriesConsumption)
            {
                if(categorieConsumption.TVA)
                {
                    totalTVA += categorieConsumption.revenues * categorieConsumption.TVA / (100 + categorieConsumption.TVA);
                }
            }
            var dateOpenFormated = ((barInfos.dateOpen.getDate() > 9) ? barInfos.dateOpen.getDate() : ('0' + barInfos.dateOpen.getDate())) + '.' + ((barInfos.dateOpen.getMonth() > 8) ? (barInfos.dateOpen.getMonth() + 1) : ('0' + (barInfos.dateOpen.getMonth() + 1))) + '.' + barInfos.dateOpen.getFullYear();
            var hourOpenFormated = ((barInfos.dateOpen.getHours() < 10) ? ("0" + barInfos.dateOpen.getHours()) : barInfos.dateOpen.getHours()) + ':' + ((barInfos.dateOpen.getMinutes() < 10) ? ("0" + barInfos.dateOpen.getMinutes()) : barInfos.dateOpen.getMinutes()) + ':' + ((barInfos.dateOpen.getSeconds() < 10) ? ("0" + barInfos.dateOpen.getSeconds()) : barInfos.dateOpen.getSeconds());
            var dateCloseFormated = ((barInfos.dateClose.getDate() > 9) ? barInfos.dateClose.getDate() : ('0' + barInfos.dateClose.getDate())) + '.' + ((barInfos.dateClose.getMonth() > 8) ? (barInfos.dateClose.getMonth() + 1) : ('0' + (barInfos.dateClose.getMonth() + 1))) + '.' + barInfos.dateClose.getFullYear();
            var hourCloseFormated = ((barInfos.dateClose.getHours() < 10) ? ("0" + barInfos.dateClose.getHours()) : barInfos.dateClose.getHours()) + ':' + ((barInfos.dateClose.getMinutes() < 10) ? ("0" + barInfos.dateClose.getMinutes()) : barInfos.dateClose.getMinutes()) + ':' + ((barInfos.dateClose.getSeconds() < 10) ? ("0" + barInfos.dateClose.getSeconds()) : barInfos.dateClose.getSeconds());
            setFontSize(0, printer);
            printer.alignCenter();
            printer.bold(true);
            for(var info of barInfos.infos)
            {
                printer.println(info);
            }
            printer.bold(false);
            printer.newLine();
            printer.println("DÉCOMPTE FERMETURE DE CAISSE");
            printer.alignLeft();
            printer.newLine();
            printer.drawLine();
            printer.leftRight("Date d'ouverture :", dateOpenFormated + " " + hourOpenFormated);
            printer.leftRight("Effectué par :", barInfos.userOpen);
            printer.drawLine();
            printer.leftRight("Date de fermeture :", dateCloseFormated + " " + hourCloseFormated);
            printer.leftRight("Effectué par :", barInfos.userClose);
            printer.drawLine();
            printer.drawLine();
            printer.leftRight("CA TTC brut :", (Math.round(amountsInfos.totalRevenues * 100) / 100).toFixed(2));
            printer.leftRight("Rabais TTC :", (Math.round(amountsInfos.discountTTC * 100) / 100).toFixed(2));
            printer.leftRight("CA net TTC :", (Math.round(totalNetRevenues * 100) / 100).toFixed(2));
            printer.leftRight("TVA Total :", (Math.round(totalTVA * 100) / 100).toFixed(2));
            printer.leftRight("CA HT :", (Math.round((totalNetRevenues - totalTVA) * 100) / 100).toFixed(2));
            printer.leftRight("Nombre de tickets :", (Math.round(amountsInfos.nbrTickets * 100) / 100).toFixed(2));
            printer.leftRight("Valeur moyenne du ticket :", (Math.round((totalNetRevenues / amountsInfos.nbrTickets) * 100) / 100).toFixed(2));
            printer.drawLine();
            printer.println("Contrôle de caisse")
            printer.leftRight("Attendu :", (Math.round(amountsInfos.turnoverExpected * 100) / 100).toFixed(2));
            printer.leftRight("Réel :", (Math.round(amountsInfos.turnoverActual * 100) / 100).toFixed(2));
            printer.leftRight("Écart :", (Math.round((amountsInfos.turnoverActual - amountsInfos.turnoverExpected) * 100) / 100).toFixed(2));
            printer.drawLine();
            printer.println("Mode de paiements TTC");
            printer.leftRight("ESPECES :", (Math.round(amountsInfos.cashRevenues * 100) / 100).toFixed(2));
            printer.leftRight("CARTE :", (Math.round(amountsInfos.cardRevenues * 100) / 100).toFixed(2));
            printer.leftRight("EN LIGNE :", (Math.round(amountsInfos.onlineRevenues * 100) / 100).toFixed(2));
            printer.drawLine();
            for(var categorieConsumption of categoriesConsumption)
            {
                var categorieTotalTVA = categorieConsumption.revenues * categorieConsumption.TVA / (100 + categorieConsumption.TVA);
                printer.println(categorieConsumption.name);
                printer.leftRight("CA TTC :", (Math.round(categorieConsumption.revenues * 100) / 100).toFixed(2));
                if(categorieConsumption.TVA)
                {
                    printer.leftRight("TVA :", (Math.round(categorieConsumption.TVA * 100) / 100).toFixed(2) + "%");
                    printer.leftRight("TVA Total :", (Math.round(categorieTotalTVA * 100) / 100).toFixed(2));
                    printer.leftRight("CA HT:", (Math.round((categorieConsumption.revenues - categorieTotalTVA) * 100) / 100).toFixed(2));
                }
                printer.drawLine();
            }
            printer.drawLine();
            printer.cut();
            try {
                printer.execute(); 
                printer.clear();
                console.log("Print done!");
            }
            catch (error) {
                console.error("Print failed:", error);
            }
        }catch(e) {
            console.log(e);
        }  
    });
}

exports.printReceiptWithNumber = printReceiptWithNumber;
exports.printDailyCount = printDailyCount;