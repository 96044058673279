import React from 'react';
import {Text, TouchableOpacity, StyleSheet, Image, Platform, ScrollView} from 'react-native';
import HomeIcon from './../assets/icons/home.svg';
import CartIcon from './../assets/icons/cart.svg';
import OrdersIcon from './../assets/icons/orders.svg';
import ProfileIcon from './../assets/icons/profile.svg';
import TicketIcon from './../assets/icons/ticket.svg';
import I18n from './../utils/i18n';
import config from '../config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { eventEmitter } from '../utils/events';

class BottomNavigationBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        role: null,
        showNotificationDot: null
    };
  }

  async componentDidMount() {
    this.setState({
        role: await AsyncStorage.getItem('role')
    });

    eventEmitter.on('showNotificationDot', async (key) => {
        this.setState({
            showNotificationDot: key
        });
    })
  }

  componentDidUpdate(nextProps, nextState) {
    nextState = {
        ...nextState,
        ...nextProps
    }
  }

  componentWillUnmount() {
    eventEmitter.removeAllListeners('showNotificationDot');
  }

  render() {

    return (
      <ScrollView horizontal={true} style={{height: 80, flexDirection: 'row'}}
        contentContainerStyle={{justifyContent: 'space-evenly', alignItems: 'center', minWidth: '100%'}}
      >
        <TouchableOpacity
            // onPress={() => this.props.navigation.navigate('Home')}
            onPress={() => {
                // navigate with reset index
                this.props.navigation.reset({
                    index: 0,
                    routes: [{ name: 'Home' }],
                });
            }}
            style={this.props.activeTab == 'Home' ? styles.button_active : styles.button}
        >
            {(Platform.OS === 'web') ? (
                <Image source={require('./../assets/icons/home.svg')} style={{width: 30, height: 30}} />
            ) : (
                <HomeIcon />
            )}
            <Text style={this.props.activeTab == 'Home' ? styles.navigation_button_text_active : styles.navigation_button_text}>{I18n.t('home')}</Text>
        </TouchableOpacity>
        
        {(this.state.role == config.roles.USER.key || this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) ? (
            <TouchableOpacity
                onPress={() => this.props.navigation.navigate('Cart')}
                style={this.props.activeTab == 'Cart' ? styles.button_active : styles.button}
            >
                {(Platform.OS === 'web') ? (
                    <Image source={require('./../assets/icons/cart.svg')} style={{width: 30, height: 30}} />
                ) : (
                    <CartIcon />
                )}
                <Text style={this.props.activeTab == 'Cart' ? styles.navigation_button_text_active : styles.navigation_button_text}>{I18n.t('cart')}</Text>
            </TouchableOpacity>
        ) : null}

        {(this.state.role == config.roles.USER.key || this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) ? (
            <TouchableOpacity
                onPress={() => {
                    this.setState({
                        showNotificationDot: null
                    });
                    this.props.navigation.navigate('Orders')
                }}
                onLongPress={() => {
                    if (this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) {
                        this.props.navigation.navigate('OrdersDisplay')
                    }
                }}
                style={this.props.activeTab == 'Orders' ? styles.button_active : styles.button}
            >
                {(Platform.OS === 'web') ? (
                    <Image source={require('./../assets/icons/ticket.svg')} style={{width: 30, height: 30}} />
                ) : (
                    <TicketIcon width={27} height={27} />
                )}
                {(this.state.showNotificationDot == 'Orders') ?
                    <Text style={{color: 'red'}}>{I18n.t('new')}</Text> : 
                    <Text style={this.props.activeTab == 'Orders' ? styles.navigation_button_text_active : styles.navigation_button_text}>{I18n.t('tickets')}</Text>
                }
            </TouchableOpacity>
        ) : null}

        {(this.state.role == config.roles.USER.key || this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) ? (
            <TouchableOpacity
                onPress={() => this.props.navigation.navigate('OrderGroups')}
                style={this.props.activeTab == 'OrderGroups' ? styles.button_active : styles.button}
            >
                {(Platform.OS === 'web') ? (
                    <Image source={require('./../assets/icons/orders.svg')} style={{width: 30, height: 30}} />
                ) : (
                    <OrdersIcon width={27} height={27} />
                )}
                <Text style={this.props.activeTab == 'OrderGroups' ? styles.navigation_button_text_active : styles.navigation_button_text}>{I18n.t('orders')}</Text>
            </TouchableOpacity>
        ) : null}
        
        <TouchableOpacity
            onPress={() => this.props.navigation.navigate('Profile')}
            style={this.props.activeTab == 'Profile' ? styles.button_active : styles.button}
        >
            {(Platform.OS === 'web') ? (
                <Image source={require('./../assets/icons/profile.svg')} style={{width: 30, height: 30}} />
            ) : (
                <ProfileIcon />
            )}
            <Text style={this.props.activeTab == 'Profile' ? styles.navigation_button_text_active : styles.navigation_button_text}>{I18n.t('profile')}</Text>
        </TouchableOpacity>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
    navigation_button_text: {
        color: 'gray'
    },
    navigation_button_text_active: {
        color: 'black'
    },
    button: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: 8
    },
    button_active: {
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'black',
        borderBottomWidth: 2,
        // borderRadius: 15,
        padding: 8
    }
});

export default BottomNavigationBar;