import React from 'react';
import { View, Text, Image, Platform } from 'react-native';
import I18n from '../utils/i18n';
import { InAppBrowser } from 'react-native-inappbrowser-reborn'

class PaymentStatus extends React.Component {

    constructor(props) {
        super(props);
    }

    async componentDidMount() {

        
        if(Platform.OS === 'web') {
            // Close Window
            try {
                window.close();
            } catch (error) {
                console.log(error);
            }
        }

        // Close InAppBrowser
        try {
            await InAppBrowser.close();
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <View style={styles.container}>
                {/* icon based on status */}
                {(this.props.status === 'success') ? (
                    <>
                        <Image
                            source={require('./../assets/images/success.gif')}
                            style={{height: 200, width: 200}}
                        />
                        <Text style={styles.title}>{I18n.t('payment_successful')}</Text>
                    </>
                ) : null}
                {(this.props.status === 'failed') ? (
                    <>
                        <Image
                            source={require('./../assets/images/error.gif')}
                            style={{height: 200, width: 200}}
                        />
                        <Text style={styles.title}>{I18n.t('payment_failed')}</Text>
                    </>
                ) : null}
                {(this.props.status === 'cancel') ? (
                    <>
                        <Image
                            source={require('./../assets/images/error.gif')}
                            style={{height: 200, width: 200}}
                        />
                        <Text style={styles.title}>{I18n.t('payment_cancelled')}</Text>
                    </>
                ) : null}
                <Text style={styles.status}>{I18n.t('please_close_this_tab')}</Text>
            </View>
        );
    }

}

const styles = {
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        fontSize: 30,
        marginBottom: 20,
        fontWeight: 'bold',
        color: 'gray'
    },
    status: {
        fontSize: 20,
        marginTop: 20,
        fontWeight: 'bold',
        color: 'gray'
    }
}

export default PaymentStatus;
