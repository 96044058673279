import React from 'react';
import { Platform } from 'react-native';

class OpenUrl extends React.Component {

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        let url = window.location.href;
        url = url.split('?url=')[1];
        if (Platform.OS === 'web') {
            window.location.href = url;
        }
    }

    render() {
        return (
            <></>
        );
    }

}

export default OpenUrl;
