import config from '../config';
import commonService from './common';

const server = commonService.getAxiosInstance();

const get_order_groups = async (query={}) => {
    try {
        let headers = await commonService.getRequestHeaders();
        let queryString = commonService.getQueryStringFromObject(query);
        const response = await server.get(`/ordergroups?${queryString}`, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const get_all_order_groups = async (status = null, limit=config.orderLimit, skip=0) => {
    try {
        let headers = await commonService.getRequestHeaders();
        let url = `/ordergroups/all?limit=${limit}&skip=${skip}`;
        if(status) {
            url += `&status=${status}`;
        }

        const response = await server.get(url, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const get_order_group = async (id, options={}) => {
    try {
        let { project } = options;
        let optionsString = '?';
        if(project) {
            optionsString += `project=${project}`;
        }
        let headers = await commonService.getRequestHeaders();
        const response = await server.get(`/ordergroups/${id}${optionsString}`, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const cancel_order_group = async (order_group_id) => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.post(`/ordergroups/${order_group_id}/cancel`, {}, {
            headers
        });
        return response.data;
    } catch (error) {
        console.log(error.message)
        return error.response.data;
    }
}

const pay_now = async (order_group_id, payment_type=config.paymentType.ONLINE.key, payment_method=config.paymentMethod.PAYREXX.key) => {
    try {
        let headers = await commonService.getRequestHeaders();
        let queryString = commonService.getQueryStringFromObject({});
        const response = await server.post(`/ordergroups/${order_group_id}/pay?${queryString}`, {
            paymentType: payment_type,
            paymentMethod: payment_method
        }, {
            headers
        });
        return response.data;
    } catch (error) {
        console.log(error.message)
        return error.response.data;
    }
}

export default {
    get_order_groups,
    get_all_order_groups,
    get_order_group,
    cancel_order_group,
    pay_now
}