import I18n from './../utils/i18n';
import commonService from "./common";

const server = commonService.getAxiosInstance();


const getPaymentStatus = async (ruid) => {
    let payment_status = null;
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.get(`/payments/card_transaction/${ruid}/status`, {
            headers
        });
        payment_status = response.data;
        return payment_status;
    } catch(error) {
        console.log("Error", error.message)
        return {
            error: I18n.t('unknown_error')
        }
    }
}

const cancelPayment = async (ruid) => {
    let payment_status = null;
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.post(`/payments/card_transaction/${ruid}/cancel`, {}, {
            headers
        });
        payment_status = response.data;
        return payment_status;
    } catch(error) {
        console.log("Error", error.message)
        return {
            error: I18n.t('unknown_error')
        }
    }
}

export {
    getPaymentStatus,
    cancelPayment
}
