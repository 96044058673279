import React from "react";
import { View, Text, TouchableOpacity, StyleSheet, Image, Linking, Platform } from "react-native";
import config from "../config";
import AddressIcon from './../assets/icons/address.svg';
import QRCodeIcon2 from './../assets/icons/qrcode2.svg';
import CloseIcon from './../assets/icons/close.svg';
import I18n from "../utils/i18n";
import CustomChip from "./CustomChip";
import RightChevronIcon from './../assets/icons/right-chevron.svg';

const default_images = {
    'bar': require('./../assets/images/bar_default.jpg'),
}

class ItemHorizontal extends React.Component {
    constructor(props) {
        super(props);
    }

    openGps = (lat, lng, title = null) => {
        const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
        const latLng = `${lat},${lng}`;
        const label = title ? title : latLng;
        const url = Platform.select({
            ios: `${scheme}${label}@${latLng}`,
            android: `${scheme}${latLng}(${label})`,
            web: `https://www.google.com/maps/search/?api=1&query=${latLng}`
        });    
        Linking.openURL(url);
    }
    
    render() {
        return (
            <TouchableOpacity
                style={
                    this.props?.isOpen ?
                    {backgroundColor: 'white', padding: 10, margin: 5, marginTop: 10, flexDirection: 'column', borderRadius: 7} :
                    {backgroundColor: 'white', padding: 10, margin: 5, marginTop: 10, flexDirection: 'column', borderRadius: 7, opacity: 0.7}
                }
                // disabled={this.props?.disabled}
                onPress={this.props?.disabled ? null : this.props?.onClick}
            >
                {(this.props?.isHiddenBar && this.props?.isCancellable) ? (
                    <View style={{flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5}}>
                        <View style={{flex: 1, flexDirection: 'row'}}>
                            {(Platform.OS === 'web') ? (
                                <Image source={require('./../assets/icons/qrcode2.svg')} style={{width: 20, height: 20}} />
                            ) : (
                                <QRCodeIcon2 width={20} height={20} />
                            )}
                            <Text style={{marginLeft: 10}}>{I18n.t('added_from_scanned_qr')}</Text>
                        </View>
                        <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <TouchableOpacity
                                onPress={this.props?.onCancel ? this.props?.onCancel : null}
                            >
                                {(Platform.OS === 'web') ? (
                                    <Image source={require('./../assets/icons/close.svg')} style={{width: 20, height: 20}} />
                                ) : (
                                    <CloseIcon width={20} height={20} />
                                )}
                            </TouchableOpacity>
                        </View>
                    </View>
                ) : null}
                <View style={{flexDirection: 'row'}}>
                    {(this.props?.image_link || this.props?.default_image) ? (
                        <View style={{width: '33%', height: 100}}>
                            <Image
                                style={Platform.OS == "web" ? {width: '100%', height: '8vh', borderRadius: 7} : {width: '100%', height: '100%', borderRadius: 7}}
                                source={ this.props?.image_link ? {uri: this.props.image_link} : default_images[this.props.default_image] }
                            />
                            {(this.props?.location?.coordinates?.[0] && this.props?.location?.coordinates?.[1]) ? (
                                <View style={{position: 'absolute', margin: 3}}>
                                    <TouchableOpacity
                                        style={{width: 60, height: 40, borderRadius: 10, backgroundColor: config.primaryColor, justifyContent: 'center', alignItems: 'center'}}
                                        onPress={() => this.openGps(this.props?.location?.coordinates?.[1], this.props?.location?.coordinates?.[0], this.props?.title)}
                                    >
                                        {(Platform.OS === 'web') ? (
                                            <Image source={require('./../assets/icons/address.svg')} style={{width: 20, height: 20}} />
                                        ) : (
                                            <AddressIcon width={20} height={20} />
                                        )}
                                    </TouchableOpacity>
                                </View>
                            ) : null}
                        </View>
                    ) : null}
                    <View style={{width: '66%', marginLeft: 10, justifyContent: 'center'}}>
                        {this.props?.title ? (
                            <Text style={{fontWeight: '700', fontSize: 20, marginBottom: 5, color: 'black'}}>{this.props.title}</Text>
                        ) : null}
                        {this.props?.secondary_title ? (
                            <Text style={{fontSize: 17, marginTop: 5, marginBottom: 5, color: 'black'}}>{this.props.secondary_title}</Text>
                        ) : null}
                        {this.props?.description ? (
                            <Text style={{fontSize: 17, marginTop: 5, color: 'black'}}>{this.props.description}</Text>
                        ) : null}
                    </View>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: 10}}>
                    {(this.props?.address?.addressLine1 || this.props?.distance) ? (
                        <View style={{justifyContent: 'center', flex: 1}}>
                            {this.props?.address?.addressLine1 ? (
                                <Text style={{color: 'black', fontWeight: '700', fontSize: 15}}>{this.props?.address?.addressLine1}</Text>
                            ) : null}
                            {this.props?.distance ? (
                                <>
                                    {(parseInt(this.props.distance) > 1000) ? (
                                        <Text style={{color: 'gray', fontWeight: '600', fontSize: 15}}>{parseInt(parseInt(this.props?.distance) / 1000).toString() + ' km away'}</Text>
                                    ) : (
                                        <Text style={{color: 'gray', fontWeight: '600', fontSize: 15}}>{parseInt(parseInt(this.props?.distance)).toString() + ' m away'}</Text>
                                    )}
                                </>
                            ) : null}
                        </View>
                    ) : <View></View>}
                    {!this.props?.isOpen ? (
                        <View style={{justifyContent: 'flex-end', align: 'right'}}>
                            <CustomChip text={I18n.t('closed')} backgroundColor={config.dangerColor} color={"white"} borderColor={config.dangerColor} /> 
                        </View>
                    ) : (
                        <View style={{justifyContent: 'flex-end', align: 'right'}}>
                            {(Platform.OS === 'web') ? (
                                <Image source={require('./../assets/icons/right-chevron.svg')} style={{width: 30, height: 30}} />
                            ) : (
                                <RightChevronIcon width={30} height={30} />
                            )}
                        </View>
                    )}
                </View>
            </TouchableOpacity>
        );
    }
}

const styles = StyleSheet.create({
    navigation_button_text: {
        color: 'gray'
    },
});

export default ItemHorizontal;