import React from "react";
import { View } from "react-native";
import { QrReader } from 'react-qr-reader';

class ScanQR extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            facingMode: 'environment'
        }
    }

    render() {
        return (
            <View style={{width: '100%', maxWidth: '400px', justifyContent: 'space-around', alignSelf: 'center'}}>
                <QrReader
                    onResult={(result, error) => {
                        if (!!result) {
                            this.props.onReadCode(result?.text);
                        }
                
                        if (!!error) {
                            console.info(error);
                        }
                    }}
                    constraints={{facingMode: this.state.facingMode}}
                />
            </View>
        )
    }
}

export default ScanQR;