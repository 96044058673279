import commonService from './common';

const server = commonService.getAxiosInstance();

const save_report_data = async (payload) => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.post(`/reports`, payload, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export default {
    save_report_data
}