import React from 'react';
import {View, Text, SafeAreaView, StyleSheet, ScrollView, TextInput, TouchableOpacity, KeyboardAvoidingView, TouchableWithoutFeedback, Keyboard, Platform} from 'react-native';
import config from '../config';
import authService from '../services/auth';
import I18n from "../utils/i18n";
import AlertPopUpModal from '../components/AlertPopUpModal';
import AsyncStorage from '@react-native-async-storage/async-storage';
import commonStyles from './../styles/common';
import CountryList from '../components/CountryList';
import profileService from '../services/profile';

class RegisterWithPhoneNumber extends React.Component {
  constructor(props) {
    super(props);

    this.redirectTo =  this.props?.route?.params?.redirectTo || this.props.redirectTo;
    this.barId = this.props?.route?.params?.barId || this.props.barId;
    this.tableNumber = this.props?.route?.params?.tableNumber || this.props.tableNumber;

    this.default_modal_props = {
      icon: '',
      title: '',
      text: '',
    }

    this.loading_modal_props = {
      ...this.default_modal_props,
      icon: 'loading',
      title: I18n.t('loading')
    }

    this.state = {
      country_code: '',
      phone_number: '',
      otp: '',
      fullname: '',
      country_code_pristine: true,
      phone_number_pristine: true,
      fullname_pristine: true,
      otp_pristine: true,
      otp_sent: false,
      otp_timer: 0,
      otp_tries: 0,
      loading: false,
      is_verified: false,
      isModalVisible: false,
      modal: {
        icon: '',
        title: '',
        text: '',
        okCallback: null
      }
    };
  }

  async sendOtp() {

    if (!this.validatePhoneNumber()) {
      return
    }

    this.setState({
      loading: true,
      isModalVisible: true,
      modal: {
        ...this.loading_modal_props
      }
    });

    let country_code = this.state.country_code;
    let phone_number = this.state.phone_number;
    // remove the leading '0' in phone number
    if (phone_number[0] === '0') {
      phone_number = phone_number.substring(1);
    }
    let payload = {
      phonenumber: country_code + phone_number
    }
    let registerRes = await authService.loginWithPhoneNumber(payload);
    if (registerRes.error) {
      this.setState({
        loading: true,
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t(registerRes.error_code),
          okCallback: () => {
            this.setState({
              loading: false,
              isModalVisible: false
            });
          }
        }
      });
      return
    }

    this.setState({
      otp_sent: true,
      otp_timer: 60,
      loading: false,
      isModalVisible: false
    });

    this.otpTimer = setInterval(() => {
      if (this.state.otp_timer > 0) {
        this.setState({
          otp_timer: this.state.otp_timer - 1
        });
      } else {
        clearInterval(this.otpTimer);
        this.setState({
          otp_timer: 0
        });
      }
    }, 1000);
  }

  async login() {
    if (!this.validatePhoneNumber() || !this.validateOtp()) {
      return
    }

    this.setState({
      loading: true,
      isModalVisible: true,
      modal: {
        ...this.loading_modal_props
      }
    });

    let country_code = this.state.country_code;
    let phone_number = this.state.phone_number;
    // remove the leading '0' in phone number
    if (phone_number[0] === '0') {
      phone_number = phone_number.substring(1);
    }
    let payload = {
      phonenumber: country_code + phone_number,
      otp: this.state.otp
    }

    let registerRes = await authService.verifyLoginWithPhoneNumber(payload);
    if (registerRes.error) {
      this.setState({
        loading: true,
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t(registerRes.error_code),
          okCallback: () => {
            this.setState({
              loading: false,
              isModalVisible: false
            });
          }
        }
      });
      return
    }
    

    await AsyncStorage.setItem(config.tokenKey, registerRes.token);
    await AsyncStorage.setItem(config.roleKey, registerRes.role);
    await AsyncStorage.setItem(config.roleValueKey, String(registerRes.value));
    await AsyncStorage.setItem(config.barsKey, JSON.stringify(registerRes.bars));

    
    this.setState({
      loading: true,
      is_verified: true,
      isModalVisible: true,
      fullname: registerRes?.fullname ? registerRes.fullname : '',
      modal: {
        ...this.default_modal_props,
        icon: 'success',
        title: I18n.t('success'),
        okCallback: async () => {
          this.setState({
            loading: false,
            isModalVisible: false,
          });
        }
      }
    });


  }

  validateCountryCode() {
    let country_code = this.state.country_code;
    let country_code_regex = new RegExp('^[0-9]{1,3}$');
    return country_code_regex.test(country_code);
  }

  validatePhoneNumber() {
    let phone_number = this.state.phone_number;
    // Some phone numbers are 9 digits
    let phone_number_regex = new RegExp('^[0-9]{7,14}$');
    return phone_number_regex.test(phone_number);
  }

  validateFullname() {
    let fullname = this.state.fullname;
    if (!fullname || !fullname.length) {
      return false;
    }
    return true;
  }

  validateOtp() {
    let otp = this.state.otp;
    let otp_regex = new RegExp('^[0-9]{3}$');
    return otp_regex.test(otp);
  }

  validateForm() {
    if (
      this.state.phone_number === '' ||
      this.state.otp === ''
    ) {
      return false;
    }

    // validate phone number with regex
    if (!this.validatePhoneNumber()) {
      return false;
    }

    return true;
  }

  countryPickerRender() {
    return (
      <>

        <CountryList
          onCountryChange={(country) => {
            this.setState({
              country_code: country.tel,
              country_code_pristine: false
            });
          }}
        />
        {(this.validateCountryCode() || this.state.country_code_pristine) ? null : <Text style={styles.inputHelper}>{I18n.t('invalid_country_code')}</Text>}
      </>
    )
  }

  phoneNumberInputRender() {
    return (
      <>
        <Text style={styles.inputHeader}>{I18n.t('phone_number')}</Text>
        <TextInput
          ref={(input) => { this.phoneNumberInput = input; }}
          style={styles.input}
          placeholder={I18n.t('phone_number')}
          placeholderTextColor={'gray'}
          onChangeText={(e) => {
            this.setState({ phone_number: e, phone_number_pristine: false });
          }}
          value={this.state.phone_number}
          autoCapitalize='none'
          returnKeyType="send"
          onSubmitEditing={() => { this.sendOtp(); }}
          keyboardType="phone-pad"
          editable={!this.state.otp_sent}
        />
        {(this.validatePhoneNumber() || this.state.phone_number_pristine) ? null : <Text style={styles.inputHelper}>{I18n.t('invalid_phone_number')}</Text>}
      </>
    )
  }

  otpInputRender() {
    return (
      <>
        <Text style={styles.inputHeader}>{I18n.t('code')}</Text>
        <TextInput
          ref={(input) => { this.otpInput = input; }}
          style={styles.input}
          placeholder={I18n.t('code')}
          placeholderTextColor={'gray'}
          onChangeText={(e) => {
            this.setState({ otp: e, otp_prinstine: false });
          }}
          value={this.state.otp}
          returnKeyType="done"
        />
        {(this.validateOtp() || this.state.otp_pristine) ? null : <Text style={styles.inputHelper}>{I18n.t('code_should_be_3_characters')}</Text>}
      </>
    )
  }

  fullnameInputRender() {
    return (
      <>
        <Text style={styles.inputHeader}>{I18n.t('full_name')}</Text>
        <TextInput
          ref={(input) => { this.fullnameInput = input }}
          style={styles.input}
          placeholder={I18n.t('full_name')}
          placeholderTextColor={'gray'}
          onChangeText={(e) => {
            this.setState({ fullname: e, fullname_pristine: false });
          }}
          value={this.state.fullname}
          autoCapitalize='none'
          returnKeyType="next"
        />
        {(this.validateFullname() || this.state.fullname_pristine) ? null : <Text style={styles.inputHelper}>{I18n.t('invalid_fullname')}</Text>}
      </>
    )
  }


  render() {
    return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
      >
        <SafeAreaView style={styles.container}>
          <View style={commonStyles.centeredView}>
          <ScrollView style={styles.scrollView} contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}>
            
            {(!this.state.is_verified) ? (
              <>

              <Text style={styles.title}>{I18n.t('continue_with_phone_number')}</Text>

              {this.countryPickerRender()}

              {this.phoneNumberInputRender()}

              {(!this.state.otp_sent) ? (
                <TouchableOpacity
                  style={(this.validatePhoneNumber() && this.validateCountryCode()) ? styles.button : styles.disabled_button}
                  disabled={(!this.validatePhoneNumber() || !this.validateCountryCode()) || this.state.otp_sent}
                  onPress={() => {
                    this.sendOtp();
                  }}
                >
                  <Text style={styles.buttonText}>{I18n.t('send_code')}</Text>
                </TouchableOpacity>
              ) : null}

              {(this.state.otp_sent) ? (
                <>
                  <View style={{flexDirection: 'row', justifyContent: 'space-between', margin: 10}}>
                    <View>
                      <Text style={{color: 'black'}}>
                        {I18n.t('code_sent_to')} <Text style={{ color: config.primaryColor }}>{this.state.phone_number}</Text>
                      </Text>
                    </View>
                    <View>
                      <Text style={{color: 'black'}}
                        onPress={() => {
                          this.setState({
                            otp_sent: false,
                            otp_timer: 0
                          });
                          if(this.otpTimer)
                            clearInterval(this.otpTimer);
                        }}
                      >{I18n.t('edit')}</Text>
                    </View>
                  </View>

                  {(this.state.otp_timer > 0) ? (
                    <Text style={{margin: 10, color: 'black'}}>
                      {I18n.t('resend_code_in')} <Text style={{ color: config.primaryColor }}>{this.state.otp_timer}</Text> {I18n.t('seconds')}
                    </Text>
                  ) : null}
                </>
              ) : null}

              {(this.state.otp_sent && this.state.otp_timer == 0) ? (
                <TouchableOpacity
                  style={(!this.validatePhoneNumber() || this.state.otp_timer > 0) ? styles.disabled_button : styles.button}
                  disabled={!this.validatePhoneNumber() || this.state.otp_timer > 0}
                  onPress={() => {
                    this.sendOtp();
                  }}
                >
                  <Text style={styles.buttonText}>{I18n.t('resend_code')}</Text>
                </TouchableOpacity>
              ) : null}

              {(this.state.otp_sent) ? (
                <>
                  {this.otpInputRender()}
                  <TouchableOpacity
                    style={(!this.validatePhoneNumber() || !this.validateOtp() || this.state.loading) ? styles.disabled_button : styles.button}
                    onPress={() => {
                      this.login();
                    }}
                    disabled = {(!this.validatePhoneNumber() || !this.validateOtp() || this.state.loading)}
                  >
                    <Text style={styles.buttonText}>{I18n.t('validate')}</Text>
                  </TouchableOpacity>
                </>
              ) : null}

              <Text style={{textAlign: 'center', margin: 20, color: 'black'}}>
                {I18n.t('already_have_an_account')} <Text style={{ color: config.primaryColor }} onPress={async () => {
                  this.setState({
                    loading: true,
                    isModalVisible: true,
                    modal: this.loading_modal_props
                  });
                  this.props.navigation.reset({
                    index: 0,
                    routes: [{ name: 'Login', params: { redirectTo: this.redirectTo, barId: this.barId, tableNumber: this.tableNumber } }],
                  });
                  this.setState({
                    loading: false,
                    isModalVisible: false
                  });
                }}>{I18n.t('login')}</Text>
              </Text>

            </>
            ) : null}


            {(this.state.is_verified) ? (
              <>
                {this.fullnameInputRender()}

                <TouchableOpacity
                  style={(!this.validateFullname() || this.state.loading) ? styles.disabled_button : styles.button}
                  onPress={async () => {
                    this.setState({
                      loading: true,
                      isModalVisible: true,
                      modal: this.loading_modal_props
                    });

                    // TODO - Save full name
                    let payload = {
                      fullname: this.state.fullname
                    }

                    try {
                      await profileService.update_profile(payload);
                    } catch (e) {
                      console.log("Error updating fullname", e.message);
                    }


                    try {
                      this.props.navigation.reset({
                        index: 0,
                        routes: [{ name: this.redirectTo ? this.redirectTo : 'Home', params: { barId: this.barId, tableNumber: this.tableNumber } }],
                      });
                      this.setState({
                        loading: false,
                        isModalVisible: false
                      });
                    } catch (e) {
                      this.setState({
                        loading: true,
                        isModalVisible: true,
                        modal: {
                          ...this.default_modal_props,
                          icon: 'error',
                          title: I18n.t('unknown_error_occurred'),
                          text: I18n.t('please_login_again'),
                          okCallback: () => {
                            this.props.navigation.reset({
                              index: 0,
                              routes: [{ name: 'Login', params: { redirectTo: this.redirectTo, barId: this.barId, tableNumber: this.tableNumber } }]
                            });
                            this.setState({
                              loading: false,
                              isModalVisible: false
                            });
                          }
                        }
                      })
                    }

                  }}
                  disabled = {(!this.validateFullname() || this.state.loading)}
                >
                  <Text style={styles.buttonText}>{I18n.t('continue')}</Text>
                </TouchableOpacity>
              </>
            ) : null}

              
              <AlertPopUpModal
                isVisible={this.state.isModalVisible}
                {...this.state.modal}
                cancelRequestCallback={() => {
                  this.setState({
                    loading: false,
                    isModalVisible: false
                  })
                }}
              ></AlertPopUpModal>

          </ScrollView>
          </View>
        </SafeAreaView>
      </KeyboardAvoidingView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  title: {
    padding: 15,
    margin: 20,
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
    color: config.primaryColor
  },
  inputHeader: {
    // margin: 10,
    padding: 15,
    paddingBottom: 10,
    fontSize: 15,
    color: 'black'
  },
  input: {
    // borderWidth: 0,
    // borderColor: config.primaryColor,
    borderRadius: 5,
    height: 50,
    borderColor: '#dfdfdf',
    borderWidth: 1,
    margin: 10,
    marginTop: 0,
    padding: 15,
    color: 'black'
  },
  inputHelper: {
    margin: -10,
    padding: 30,
    paddingTop: 0,
    paddingBottom: 10,
    fontSize: 12,
    color: 'red'
  },
  scrollView: {
    backgroundColor: 'white',
    marginHorizontal: 0
  },
  text: {
    fontSize: 42,
  },
  button: {
    backgroundColor: config.primaryColor,
    padding: 15,
    margin: 10,
    marginTop: 20,
    borderRadius: 5,
    alignItems: 'center',
  },
  disabled_button: {
    backgroundColor: 'gray',
    padding: 15,
    margin: 10,
    marginTop: 20,
    borderRadius: 5,
    alignItems: 'center',
  },
  buttonText: {
    color: 'white',
    fontSize: 20,
  }
});

export default RegisterWithPhoneNumber;
