import React from 'react';
import OrderGroupDetails from './OrderGroupDetails';

class OrderGroupHistoryDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <OrderGroupDetails
        {...this.props}
        history={true}
      />
    );
  }
}

export default OrderGroupHistoryDetails;
