import React from "react";
import { View, Text, StyleSheet, TouchableOpacity, ScrollView } from "react-native";
import I18n from "../utils/i18n";
import config from "../config";
import AsyncStorage from "@react-native-async-storage/async-storage";

class OrderStatusSelection extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            statusTabs: [],
            activeStatusTabs: []
        }

    }

    async componentDidMount() {
        let role = await AsyncStorage.getItem(config.roleKey);

        let statusTabs = Object.keys(config.orderStatus).map((status) => {
            return config.orderStatus[status].key
        });
        if (role && (role == config.roles.CASHIER.key || role == config.roles.SERVER.key)) {
            statusTabs = statusTabs.filter((status) => {
                return status !== config.orderStatus.PAYMENT_PENDING.key && status != config.orderStatus.PAYMENT_FAILED.key
            })
        }

        this.setState({ statusTabs })
    }

    componentDidUpdate(nextProps, nextState) {
        nextState = {
            ...nextState,
            ...nextProps
        }
    }

    toggleActiveStatusTab(status) {
        let activeStatusTabs = this.state.activeStatusTabs;
        if (activeStatusTabs.includes(status)) {
            activeStatusTabs = activeStatusTabs.filter((activeStatus) => {
                return activeStatus !== status
            })
        } else {
            activeStatusTabs.push(status)
        }
        this.setState({ activeStatusTabs })

        this.props?.onStatusChange?.(activeStatusTabs)
    }

    render() {
        return (
            <View style={styles.container}>
                <View style={styles.statusTabsContainer}>
                <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                    {this.state.statusTabs.map((status, index) => {
                        return (
                            <TouchableOpacity
                                key={index} style={ this.state.activeStatusTabs.includes(status) ? styles.activeStatusTab : styles.statusTab}
                                onPress={() => this.toggleActiveStatusTab(status)}
                            >
                                <Text style={ this.state.activeStatusTabs.includes(status) ? styles.activeStatusTabText : styles.statusTabText}>{I18n.t(status)}</Text>
                            </TouchableOpacity>
                        )
                    })}
                </ScrollView>
                </View>
            </View>
        )
    }

}

const styles = StyleSheet.create({

    container: {
        // flex: 1,
        backgroundColor: 'darkgray',
        // backgroundColor: 'red',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
        paddingBottom: 10,
        paddingTop: 10,
        width: '100%',
        overflowY: 'scroll'
    },

    statusTabsContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },

    statusTab: {
        backgroundColor: 'white',
        padding: 5,
        marginHorizontal: 5,
        borderRadius: 5,
        borderWidth: 2,
        borderColor: config.primaryColor
    },
    
    activeStatusTab: {
        backgroundColor: config.primaryColor,
        padding: 5,
        marginHorizontal: 5,
        borderRadius: 5,
        borderWidth: 2,
        borderColor: config.primaryColor
    },

    statusTabText: {
        color: '#000',
        fontSize: 15,
        fontWeight: '700'
    },
    
    activeStatusTabText: {
        color: '#fff',
        fontSize: 15,
        fontWeight: '700'
    }

});

export default OrderStatusSelection;