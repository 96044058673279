import commonService from './common';

const server = commonService.getAxiosInstance();

const update_profile = async (payload) => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.patch(`/profile`, payload, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export default {
    update_profile
}
