import commonService from './common';

const server = commonService.getAxiosInstance();

const get_gateway = async (gateway_id) => {
    try {
        let headers = await commonService.getRequestHeaders();
        const response = await server.get(`/payrexx/gateway/${gateway_id}`, {
            headers
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export default {
    get_gateway
}
